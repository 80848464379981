import { SET_ERROR, SET_LOADING_STATUS } from '@/store/modules/status/mutationTypes';

const setError = ({ commit }, { stateKey, error }) => {
    commit(SET_ERROR, { stateKey, error });
};

const setLoadingStatus = ({ commit }, { stateKey, isLoading }) => {
    commit(SET_LOADING_STATUS, { stateKey, isLoading });
};

export default {
    setError,
    setLoadingStatus,
};
