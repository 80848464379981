import actions from '@/store/modules/oneBoard/actions';
import getters from '@/store/modules/oneBoard/getters';
import mutations from '@/store/modules/oneBoard/mutations';
import { finance } from '@/store/modules/oneBoard/finance';
import { performance } from '@/store/modules/oneBoard/performance';
import { quality } from '@/store/modules/oneBoard/quality';

export default {
    namespaced: true,
    modules: { finance, performance, quality },
    state: {
        concepts: [],
        currentKitchen: {},
        currentLocation: {},
        kitchens: [],
        liveStatus: {},
        liveStatuses: [],
        locations: [],
        platforms: [],
        restaurants: {},
        reviews: [],
        missingItemsOrders: {},
        missingItemsTimeSeries: {},
    },
    actions,
    getters,
    mutations,
};
