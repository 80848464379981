import actions from '@/store/modules/orders/actions';
import getters from '@/store/modules/orders/getters';
import mutations from '@/store/modules/orders/mutations';

export default {
    namespaced: true,
    state: {
        orders: [],
    },
    actions,
    getters,
    mutations,
};
