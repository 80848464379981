import actions from '@/store/modules/invoices/actions';
import getters from '@/store/modules/invoices/getters';
import mutations from '@/store/modules/invoices/mutations';

export const invoices = {
    namespaced: true,
    state: {
        publishedInvoices: [],
    },
    actions,
    getters,
    mutations,
};
