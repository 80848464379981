import {
    SET_PERFORMANCE_COMPARISON_SUMMARY,
    SET_PERFORMANCE_SPLIT_CHART_BY_CONCEPT,
    SET_PERFORMANCE_SPLIT_CHART_BY_PLATFORM,
    SET_PERFORMANCE_SUMMARY,
    SET_PERFORMANCE_TIME_CHART,
    SET_PERFORMANCE_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/performance/mutationTypes';

export default {
    [SET_PERFORMANCE_SPLIT_CHART_BY_CONCEPT]: (state, splitChartByConcept) => {
        state.splitChartByConcept = splitChartByConcept;
    },
    [SET_PERFORMANCE_SPLIT_CHART_BY_PLATFORM]: (state, splitChartByPlatform) => {
        state.splitChartByPlatform = splitChartByPlatform;
    },
    [SET_PERFORMANCE_TIME_CHART]: (state, timeChart) => {
        state.timeChart = timeChart;
    },
    [SET_PERFORMANCE_SUMMARY]: (state, summary) => {
        state.summary = summary;
    },
    [SET_PERFORMANCE_COMPARISON_SUMMARY]: (state, comparisonSummary) => {
        state.comparisonSummary = comparisonSummary;
    },
    [SET_PERFORMANCE_TIME_SERIES_BY_LOCATION]: (state, timeseriesByLocation) => {
        state.timeseriesByLocation = timeseriesByLocation;
    },
};
