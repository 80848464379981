import { OPENING_HOURS_STATUS } from '@/store/modules/openingHours/actions';

const getKitchenDataByKitchenUuid = (state) => {
    return state.kitchensDataByKitchenUuid;
};

const hasActiveRestaurants = (state) => (kitchenUuid, conceptUuid) => {
    return Object.values(state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants).some(
        ({ openingHoursStatus, specialHoursStatus }) =>
            ![openingHoursStatus, specialHoursStatus].includes(OPENING_HOURS_STATUS.Draft)
    );
};

const hasRestaurantsOnStatus = (state) => (kitchenUuid, conceptUuid, searchedStatus) => {
    return Object.values(state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants).some(
        ({ openingHoursStatus, specialHoursStatus }) =>
            [openingHoursStatus, specialHoursStatus].includes(searchedStatus)
    );
};

const getConceptStatus = (_, getters) => (kitchenUuid, conceptUuid) => {
    const isConceptUpdating = getters.hasRestaurantsOnStatus(kitchenUuid, conceptUuid, 'UPDATE');

    if (isConceptUpdating) return 'UPDATE';

    const isConceptOnFailure = getters.hasRestaurantsOnStatus(kitchenUuid, conceptUuid, OPENING_HOURS_STATUS.Failure);

    const isConceptActive = !hasRestaurantsOnStatus(kitchenUuid, conceptUuid, OPENING_HOURS_STATUS.Draft);

    const hasSpecialHoursRequestFailed =
        isConceptActive &&
        !getters.getSpecialHours(kitchenUuid, conceptUuid) &&
        !getters.getOpeningHoursLoadingStatus(kitchenUuid, 'specialHours');

    if (hasSpecialHoursRequestFailed || isConceptOnFailure) return OPENING_HOURS_STATUS.Failure;

    return OPENING_HOURS_STATUS.Success;
};

const areOpeningHoursUpdating = (state) => (kitchenUuid, conceptUuid) => {
    return Object.values(
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants
    ).every(({ openingHoursStatus }) => openingHoursStatus === 'UPDATE');
};

const areSpecialHoursUpdating = (state) => (kitchenUuid, conceptUuid) => {
    return Object.values(
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants
    ).every(({ specialHoursStatus }) => specialHoursStatus === 'UPDATE');
};

const getOpeningHours = (state) => (kitchenUuid, conceptUuid) => {
    const searchedConcept = state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid];

    return searchedConcept.openingHours;
};

const getSpecialHours = (state) => (kitchenUuid, conceptUuid) => {
    const concept = state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid];

    return concept.specialHours;
};

const getOpeningHoursLoadingStatus = (state) => (kitchenUuid, container) => {
    return state.kitchensLoadingMap[kitchenUuid]?.[container];
};

const getRestaurantsUuidsByConceptUuid = (state) => (kitchenUuid, conceptUuid) => {
    const restaurants = state.kitchensDataByKitchenUuid[kitchenUuid].restaurantsWithStatus[conceptUuid].restaurants;

    return Object.keys(restaurants);
};

const getConceptUuids = (state) => (kitchenUuid) => {
    const concepts = state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus;

    return Object.keys(concepts);
};

const getRestaurants = (state) => (kitchenUuid, conceptUuid) => {
    return Object.values(state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants);
};

export default {
    getConceptStatus,
    getConceptUuids,
    getKitchenDataByKitchenUuid,
    getOpeningHours,
    getOpeningHoursLoadingStatus,
    areOpeningHoursUpdating,
    getRestaurants,
    getRestaurantsUuidsByConceptUuid,
    getSpecialHours,
    areSpecialHoursUpdating,
    hasActiveRestaurants,
    hasRestaurantsOnStatus,
};
