import { isEmpty } from 'lodash';
import Vue from 'vue';
import { kitchenManagerApi } from '@/apis/kitchenManagerApi';
import {
    SET_OPENING_HOURS,
    SET_SPECIAL_HOURS,
    SET_OPENING_HOURS_CONCEPT_STATUS,
    SET_OPENING_HOURS_RESTAURANT_STATUS,
    SET_SPECIAL_HOURS_RESTAURANT_STATUS,
    SET_SPECIAL_HOURS_CONCEPT_STATUS,
    SET_KITCHEN_LOADING_STATUS,
    SET_OPENING_HOURS_FETCH_REQUEST,
    CLEAR_OPENING_HOURS_FETCH_REQUEST,
} from '@/store/modules/openingHours/mutationsTypes';
import { CLEAR_SPECIAL_HOURS_FETCH_REQUEST, SET_SPECIAL_HOURS_FETCH_REQUEST } from './mutationsTypes';
import i18n from '@/i18n';

const INTERVAL_STATUS_REQUEST = 4000;
const MAXIMUM_REQUEST_CALLS = 4;

export const OPENING_HOURS_STATUS = {
    Draft: 'DRAFT',
    Failure: 'FAILURE',
    Pending: 'PENDING',
    InProgress: 'IN_PROGRESS',
    Requested: 'REQUESTED',
    Success: 'SUCCESS',
};

const isUpdatePending = (status) => [OPENING_HOURS_STATUS.InProgress, OPENING_HOURS_STATUS.Requested].includes(status);

const getStatusListSnackbarMessage = ({ updates, isSpecialHours = false }) => {
    const successesStatusesList = updates
        .filter(({ status }) => status !== OPENING_HOURS_STATUS.Failure && !isUpdatePending(status))
        .map(({ status }) => status);

    if (!successesStatusesList.length) return;

    if (
        (isSpecialHours && successesStatusesList.some((status) => status === OPENING_HOURS_STATUS.Success)) ||
        successesStatusesList.every((status) => status === OPENING_HOURS_STATUS.Success)
    ) {
        return { message: i18n.t('openingHours.regularSchedule.savedOpeningHours'), subText: '' };
    }

    if (successesStatusesList.some((status) => status === OPENING_HOURS_STATUS.Draft)) {
        return {
            message: i18n.t('openingHours.regularSchedule.savedOpeningHours'),
            subText: i18n.t('openingHours.regularSchedule.savedDraftHours'),
        };
    }
};

const fetchOpeningHours = async ({ commit }, { kitchenUuid, conceptUuids }) => {
    commit(SET_KITCHEN_LOADING_STATUS, { kitchenUuid, container: 'openingHours', isLoading: true });

    try {
        const openingHoursByKitchen = await kitchenManagerApi.fetchOpeningHours({ kitchenUuid });

        if (isEmpty(openingHoursByKitchen)) {
            commit(SET_KITCHEN_LOADING_STATUS, {
                kitchenUuid,
                container: 'openingHours',
                isLoading: false,
            });
        } else {
            conceptUuids.forEach((kitchenConceptUuid) => {
                const openingHoursByConcept = openingHoursByKitchen.filter(
                    ({ conceptUuid }) => kitchenConceptUuid === conceptUuid
                );

                if (openingHoursByConcept.length) {
                    commit(SET_OPENING_HOURS, {
                        kitchenUuid,
                        conceptUuid: kitchenConceptUuid,
                        newOpeningHours: openingHoursByConcept[0].weekdays,
                    });

                    openingHoursByConcept.forEach(({ conceptUuid, restaurantUuid, status }) => {
                        const newStatus = isUpdatePending(status) ? 'UPDATE' : status;

                        commit(SET_OPENING_HOURS_RESTAURANT_STATUS, {
                            kitchenUuid,
                            conceptUuid,
                            restaurantUuid,
                            newStatus,
                        });
                    });
                }
            });
        }
    } catch (e) {
        conceptUuids.forEach((conceptUuid) => {
            commit(SET_OPENING_HOURS, {
                kitchenUuid,
                conceptUuid,
                newOpeningHours: { hasFetchOpeningHours: true },
            });

            commit(SET_OPENING_HOURS_CONCEPT_STATUS, { kitchenUuid, conceptUuid, newStatus: 'FAILURE' });
        });

        Vue.prototype.$errorReporting.onError(e);
    } finally {
        commit(SET_KITCHEN_LOADING_STATUS, { kitchenUuid, container: 'openingHours', isLoading: false });
    }
};

const fetchSpecialHours = async ({ commit }, { kitchenUuid, conceptUuids }) => {
    commit(SET_KITCHEN_LOADING_STATUS, { kitchenUuid, container: 'specialHours', isLoading: true });

    try {
        const specialHoursByKitchen = await kitchenManagerApi.fetchReplacementSchedules({ kitchenUuid });

        if (isEmpty(specialHoursByKitchen)) {
            commit(SET_KITCHEN_LOADING_STATUS, {
                kitchenUuid,
                container: 'specialHours',
                isLoading: false,
            });
        } else {
            conceptUuids.forEach((kitchenConceptUuid) => {
                const specialHoursByConcept = specialHoursByKitchen.filter(
                    ({ conceptUuid, status }) =>
                        kitchenConceptUuid === conceptUuid && status !== OPENING_HOURS_STATUS.Draft
                );

                if (specialHoursByConcept.length) {
                    commit(SET_SPECIAL_HOURS, {
                        kitchenUuid,
                        conceptUuid: kitchenConceptUuid,
                        newSpecialHours: specialHoursByConcept[0].replacementDays,
                    });

                    specialHoursByConcept.forEach(({ conceptUuid, restaurantUuid, status }) => {
                        const newStatus = isUpdatePending(status) ? 'UPDATE' : status;

                        commit(SET_SPECIAL_HOURS_RESTAURANT_STATUS, {
                            kitchenUuid,
                            conceptUuid,
                            restaurantUuid,
                            newStatus,
                        });
                    });
                }
            });
        }
    } catch (e) {
        Vue.prototype.$errorReporting.onError(e);

        conceptUuids.forEach((conceptUuid) => {
            commit(SET_SPECIAL_HOURS_CONCEPT_STATUS, { kitchenUuid, conceptUuid, newStatus: 'error' });
        });
    } finally {
        commit(SET_KITCHEN_LOADING_STATUS, { kitchenUuid, container: 'specialHours', isLoading: false });
    }
};

const updateLocationOpeningHours = async (
    { getters, commit, dispatch },
    { shiftsData, applyToAllConcepts, currentConcept, kitchenUuid }
) => {
    const conceptsToUpdateUuids = applyToAllConcepts ? getters.getConceptUuids(kitchenUuid) : [currentConcept.uuid];

    conceptsToUpdateUuids.forEach((conceptUuid) =>
        commit(SET_OPENING_HOURS_CONCEPT_STATUS, { kitchenUuid, conceptUuid, newStatus: 'UPDATE' })
    );

    const restaurantsToUpdateUuids = conceptsToUpdateUuids.flatMap((conceptUuid) =>
        getters.getRestaurants(kitchenUuid, conceptUuid).map(({ restaurantUuid }) => restaurantUuid)
    );

    const openingHoursRequest = restaurantsToUpdateUuids.map((restaurantUuid) => ({
        restaurantUuid,
        weekdays: Object.values(shiftsData).map(({ shifts }) => ({ shifts })),
    }));

    const newOpeningHours = await kitchenManagerApi.updateOpeningHours({
        kitchenUuid,
        openingHoursRequest,
    });

    conceptsToUpdateUuids.forEach((conceptUuid) => {
        const conceptNewOpeningHours = newOpeningHours.filter(
            (openingHours) => openingHours.conceptUuid === conceptUuid
        );

        try {
            if (conceptNewOpeningHours.every(({ status }) => !isUpdatePending(status))) {
                commit(SET_OPENING_HOURS, {
                    kitchenUuid,
                    conceptUuid,
                    newOpeningHours: conceptNewOpeningHours[0].weekdays,
                });

                conceptNewOpeningHours.forEach(({ restaurantUuid, status }) => {
                    commit(SET_OPENING_HOURS_RESTAURANT_STATUS, {
                        kitchenUuid,
                        conceptUuid,
                        restaurantUuid,
                        newStatus: status,
                    });
                });

                const { message, subText } = getStatusListSnackbarMessage({ updates: conceptNewOpeningHours });

                dispatch(
                    'notifier/showNotification',
                    {
                        message,
                        subText,
                        type: 'success',
                    },
                    { root: true }
                );
            } else {
                dispatch('requestOpeningHoursUpdateStatus', { kitchenUuid, conceptUuid });
            }
        } catch (e) {
            restaurantsToUpdateUuids.forEach((restaurantUuid) => {
                commit(SET_OPENING_HOURS_CONCEPT_STATUS, { kitchenUuid, conceptUuid, newStatus: 'FAILURE' });

                commit(SET_OPENING_HOURS_RESTAURANT_STATUS, {
                    kitchenUuid,
                    conceptUuid,
                    restaurantUuid,
                    newStatus: OPENING_HOURS_STATUS.Failure,
                });

                Vue.prototype.$errorReporting.onError(
                    new Error(
                        `failed to update opening hours for kitchen ${kitchenUuid}, concept ${currentConcept.label}, restaurant ${restaurantUuid} `
                    )
                );
            });
        }
    });
};

const updateLocationSpecialHours = async (
    { getters, commit, dispatch },
    { specialHours, applyToAllConcepts, currentConcept, kitchenUuid }
) => {
    const conceptsToUpdateUuids = applyToAllConcepts ? getters.getConceptUuids(kitchenUuid) : [currentConcept.uuid];

    conceptsToUpdateUuids.forEach((conceptUuid) =>
        commit(SET_SPECIAL_HOURS_CONCEPT_STATUS, { kitchenUuid, conceptUuid, newStatus: 'UPDATE' })
    );

    const restaurantsToUpdateUuids = conceptsToUpdateUuids.flatMap((conceptUuid) =>
        getters
            .getRestaurants(kitchenUuid, conceptUuid)
            .filter(({ specialHoursStatus }) => specialHoursStatus !== OPENING_HOURS_STATUS.Draft)
            .map(({ restaurantUuid }) => restaurantUuid)
    );

    const replacementScheduleRequest = restaurantsToUpdateUuids.map((restaurantUuid) => ({
        restaurantUuid,
        replacementDays: Object.values(specialHours).map(({ shifts, day }) => ({
            day: new Date(day),
            shifts,
        })),
    }));

    const newSpecialHours = await kitchenManagerApi.updateReplacementSchedule({
        kitchenUuid,
        replacementScheduleRequest,
    });

    conceptsToUpdateUuids.forEach((conceptUuid) => {
        try {
            if (newSpecialHours.every(({ status }) => !isUpdatePending(status))) {
                commit(SET_SPECIAL_HOURS, {
                    kitchenUuid,
                    conceptUuid,
                    newOpeningHours: newSpecialHours[0].weekdays,
                });

                newSpecialHours.forEach(({ restaurantUuid, status }) => {
                    commit(SET_SPECIAL_HOURS_RESTAURANT_STATUS, {
                        kitchenUuid,
                        conceptUuid,
                        restaurantUuid,
                        newStatus: status,
                    });
                });

                const { message, subText } = getStatusListSnackbarMessage({
                    isSpecialHours: true,
                    updates: newSpecialHours,
                });

                dispatch(
                    'notifier/showNotification',
                    {
                        message,
                        subText,
                        type: 'success',
                    },
                    { root: true }
                );
            } else {
                dispatch('requestSpecialHoursUpdateStatus', { kitchenUuid, conceptUuid });
            }
        } catch (e) {
            restaurantsToUpdateUuids.forEach((restaurantUuid) => {
                commit(SET_SPECIAL_HOURS_RESTAURANT_STATUS, {
                    kitchenUuid,
                    conceptUuid,
                    restaurantUuid,
                    newStatus: OPENING_HOURS_STATUS.Failure,
                });

                Vue.prototype.$errorReporting.onError(
                    new Error(
                        `failed to update special hours for kitchen ${kitchenUuid}, concept ${currentConcept.label}, restaurant ${restaurantUuid} `
                    )
                );
            });
        }
    });
};

const requestOpeningHoursUpdateStatus = async ({ commit, dispatch }, { kitchenUuid, conceptUuid }) => {
    let requestsSents = 0;

    const requestOpeningHoursUpdateId = setInterval(async () => {
        try {
            requestsSents++;

            const openingHoursByKitchenAndConcept = await kitchenManagerApi.fetchOpeningHours({
                kitchenUuid,
                conceptUuid,
            });

            const newOpeningHours = openingHoursByKitchenAndConcept.find(
                ({ status }) => status === OPENING_HOURS_STATUS.Success
            )?.weekdays;

            if (newOpeningHours) {
                commit(SET_OPENING_HOURS, {
                    kitchenUuid,
                    conceptUuid,
                    newOpeningHours,
                });
            }

            openingHoursByKitchenAndConcept.forEach(({ conceptUuid, restaurantUuid, status }) => {
                const newStatus = isUpdatePending(status) ? 'UPDATE' : status;

                commit(SET_OPENING_HOURS_RESTAURANT_STATUS, {
                    kitchenUuid,
                    conceptUuid,
                    restaurantUuid,
                    newStatus,
                });
            });

            const isUpdateDone = openingHoursByKitchenAndConcept.every(({ status }) => !isUpdatePending(status));

            if (isUpdateDone || requestsSents >= MAXIMUM_REQUEST_CALLS) {
                const { message, subText } = getStatusListSnackbarMessage({
                    updates: openingHoursByKitchenAndConcept,
                });

                dispatch(
                    'notifier/showNotification',
                    {
                        message,
                        subText,
                        type: 'success',
                    },
                    { root: true }
                );

                commit(CLEAR_OPENING_HOURS_FETCH_REQUEST, {
                    kitchenUuid,
                    conceptUuid,
                });
            }
        } catch (e) {
            commit(CLEAR_OPENING_HOURS_FETCH_REQUEST, {
                kitchenUuid,
                conceptUuid,
            });

            Vue.prototype.$errorReporting.onError(e);
        }
    }, INTERVAL_STATUS_REQUEST);

    commit(SET_OPENING_HOURS_FETCH_REQUEST, {
        kitchenUuid,
        conceptUuid,
        intervalId: requestOpeningHoursUpdateId,
    });
};

const requestSpecialHoursUpdateStatus = async ({ commit, dispatch }, { kitchenUuid, conceptUuid }) => {
    let requestsSents = 0;

    const requestSpecialHoursUpdateStatus = setInterval(async () => {
        try {
            requestsSents++;

            const specialHoursByKitchenAndConcept = await kitchenManagerApi.fetchReplacementSchedules({
                kitchenUuid,
                conceptUuid,
            });

            const newSpecialHours = specialHoursByKitchenAndConcept.find(
                ({ status }) => status === OPENING_HOURS_STATUS.Success
            )?.replacementDays;

            if (newSpecialHours) {
                commit(SET_SPECIAL_HOURS, {
                    kitchenUuid,
                    conceptUuid,
                    newSpecialHours,
                });
            }

            specialHoursByKitchenAndConcept.forEach(({ conceptUuid, restaurantUuid, status }) => {
                const newStatus = isUpdatePending(status) ? 'UPDATE' : status;

                commit(SET_SPECIAL_HOURS_RESTAURANT_STATUS, {
                    kitchenUuid,
                    conceptUuid,
                    restaurantUuid,
                    newStatus,
                });
            });

            const isUpdateDone = specialHoursByKitchenAndConcept.every(({ status }) => !isUpdatePending(status));

            if (isUpdateDone || requestsSents >= MAXIMUM_REQUEST_CALLS) {
                const { message } = getStatusListSnackbarMessage({
                    isSpecialHours: true,
                    updates: specialHoursByKitchenAndConcept,
                });

                dispatch(
                    'notifier/showNotification',
                    {
                        message,
                        type: 'success',
                    },
                    { root: true }
                );

                commit(CLEAR_SPECIAL_HOURS_FETCH_REQUEST, {
                    kitchenUuid,
                    conceptUuid,
                });
            }
        } catch (e) {
            commit(CLEAR_SPECIAL_HOURS_FETCH_REQUEST, {
                kitchenUuid,
                conceptUuid,
            });

            Vue.prototype.$errorReporting.onError(e);
        }
    }, INTERVAL_STATUS_REQUEST);

    commit(SET_SPECIAL_HOURS_FETCH_REQUEST, {
        kitchenUuid,
        conceptUuid,
        intervalId: requestSpecialHoursUpdateStatus,
    });
};

const clearAllIntervals = ({ state }) => {
    Object.values({ ...state.openingHoursFetchRequests, ...state.specialHoursFetchRequests }).forEach(clearInterval);
};

export default {
    clearAllIntervals,
    fetchOpeningHours,
    fetchSpecialHours,
    requestOpeningHoursUpdateStatus,
    requestSpecialHoursUpdateStatus,
    updateLocationOpeningHours,
    updateLocationSpecialHours,
};
