const getComparisonSummary = (state) => state.comparisonSummary;
const getSummary = (state) => state.summary;
const getTimeChart = (state) => state.timeChart;
const getTimeseriesByLocation = (state) => state.timeseriesByLocation;

export default {
    getComparisonSummary,
    getSummary,
    getTimeChart,
    getTimeseriesByLocation,
};
