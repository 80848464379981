import { menuManagerClient, menuManagerV2Client } from '@/apis/gatewayPlatformsClients';
import snakeize from 'snakeize';

const createMenu = (requestPayload) => menuManagerV2Client.createMenu(snakeize(requestPayload));

const fetchMenuItemNutritionalClassifications = () => menuManagerV2Client.listMenuItemNutritionalClassifications();

const fetchCategoriesDetails = ({ conceptUuid, isDeleted = false, locationUuid, platformUuid } = {}) =>
    menuManagerClient.listCategoriesDetails({ conceptUuid, isDeleted, locationUuid, platformUuid });

const fetchCategoriesVariants = ({ variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listCategoriesVariants({ variantUuid, isDeleted });

const fetchCategories = ({ conceptUuid, isDeleted = false, label, menuUuid } = {}) =>
    menuManagerClient.listCategories({ conceptUuid, isDeleted, label, menuUuid });

const fetchCategoryDetails = (categoryUuid, { conceptUuid, locationUuid, platformUuid, isDeleted = false } = {}) =>
    menuManagerClient.listCategoryDetails(categoryUuid, { conceptUuid, locationUuid, platformUuid, isDeleted });

const fetchGeneratedMenu = (generatedMenuUuid) => menuManagerV2Client.getGeneratedMenu(generatedMenuUuid);

const fetchGeneratedMenus = ({ conceptUuid, platformUuid, locationUuid, menuUuid, isDeleted = false } = {}) => {
    return menuManagerV2Client.listGeneratedMenus({
        conceptUuid,
        platformUuid,
        locationUuid,
        menuUuid,
        isDeleted,
    });
};

const fetchMenuItemLibrary = ({
    conceptUuid,
    menuUuid,
    loadMenuItemPicture = false,
    loadUsage = true,
    locationsUuids = [],
    isArchived = false,
}) =>
    menuManagerV2Client.listMenuItemsLibrary(
        snakeize({
            conceptUuid,
            menuUuid,
            loadMenuItemPicture,
            loadUsage,
            locationsUuids,
            isArchived,
        })
    );

const fetchMenu = (menuUuid) => menuManagerV2Client.getMenu(menuUuid);

const fetchMenuElementCustomerDisplay = (menuElementUuid) =>
    menuManagerV2Client.getMenuElementCustomerDisplay(menuElementUuid);

const fetchMenuElementOneTabletDisplay = (orderOneTabletDisplayRequest) =>
    menuManagerV2Client.getOneTabletDisplay(snakeize(orderOneTabletDisplayRequest));

const fetchMenuElements = ({ categoryUuid, conceptUuid, menuUuid, menuItemUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listMenuElements({ categoryUuid, conceptUuid, menuUuid, menuItemUuid, isDeleted });

const fetchMenuItem = (menuItemuuid) => menuManagerV2Client.getMenuItem(menuItemuuid);

const fetchMenuItemAvailabilities = (menuUuid, menuItemUuid) =>
    menuManagerV2Client.listMenuItemAvailabilities(menuUuid, menuItemUuid);

const fetchMenuDetails = (menuUuid, { conceptUuid, locationUuid } = {}) => {
    return menuManagerClient.listMenuDetails(menuUuid, { conceptUuid, locationUuid });
};

const fetchMenuItemClassifications = ({ isInternal, isDeleted = false, menuItemType } = {}) =>
    menuManagerClient.listMenuItemClassifications({
        is_internal: isInternal,
        is_deleted: isDeleted,
        menu_item_type: menuItemType,
    });

const fetchMenuItemCategories = ({ isInternal, isDeleted = false, menuItemType } = {}) =>
    menuManagerClient.listMenuItemCategories({
        is_internal: isInternal,
        is_deleted: isDeleted,
        menu_item_type: menuItemType,
    });

const fetchMenuItems = ({ categoryUuid, conceptUuid, label, menuItemType, isDeleted = false } = {}) =>
    menuManagerV2Client.listMenuItems({
        categoryUuid,
        conceptUuid,
        label,
        menuItemType,
        isDeleted,
    });

const fetchMenuItemsDetails = ({ conceptUuid, isDeleted = false, locationUuid, platformUuid } = {}) =>
    menuManagerV2Client.listMenuItemsDetails({ conceptUuid, isDeleted, locationUuid, platformUuid });

const fetchMenuItemsOverview = ({ menuUuid, conceptUuid = null, excludeCombos = false, unique = false } = {}) =>
    menuManagerV2Client.listMenuMenuItemsOverview(menuUuid, { conceptUuid, excludeCombos, unique });

const fetchMenuItemsVariants = ({ conceptUuid, variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listMenuItemsVariants({ conceptUuid, variantUuid, isDeleted });

const fetchMenuVariants = (menuUuid, { variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listMenuVariants(menuUuid, { variantUuid, isDeleted });

const fetchModifierGroups = ({ conceptUuid, menuUuid, label, isDeleted = false, loadUsage = false } = {}) =>
    menuManagerV2Client.listModifierGroups({
        conceptUuid,
        menuUuid,
        label,
        isDeleted,
        loadUsage,
    });

const fetchModifierGroupsDetails = ({ conceptUuid, isDeleted = false, locationUuid, platformUuid } = {}) =>
    menuManagerClient.listModifierGroupsDetails({ conceptUuid, isDeleted, locationUuid, platformUuid });

const fetchModifierGroupsVariants = ({ variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listModifierGroupsVariants({ variantUuid, isDeleted });

const fetchModifierVariants = (modifierUuid, { variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listModifierVariants(modifierUuid, { variantUuid, isDeleted });

const fetchModifiers = ({ conceptUuid, menuItemUuid, modifierGroupUuid, isDeleted = false } = {}) =>
    menuManagerClient.listModifiers({
        conceptUuid,
        menuItemUuid,
        modifierGroupUuid,
        isDeleted,
    });

const fetchModifiersVariants = ({ variantUuid, isDeleted = false } = {}) =>
    menuManagerV2Client.listModifiersVariants({ variantUuid, isDeleted });

const fetchModifierDetails = (modifierUuid, { conceptUuid, locationUuid, platformUuid, isDeleted = false } = {}) =>
    menuManagerClient.listModifierDetails(modifierUuid, {
        conceptUuid,
        locationUuid,
        platformUuid,
        isDeleted,
    });

const fetchModifierGroupDetails = (
    modifierGroupUuid,
    { conceptUuid, locationUuid, platformUuid, isDeleted = false } = {}
) =>
    menuManagerClient.listModifierGroupDetails(modifierGroupUuid, {
        conceptUuid,
        locationUuid,
        platformUuid,
        isDeleted,
    });

const deleteCategory = (categoryUuid) => menuManagerV2Client.deleteCategory(categoryUuid);

const deleteMenuItemVariant = (menuItemUuid, menuItemVariantUuid) =>
    menuManagerV2Client.deleteMenuItemVariant(menuItemUuid, menuItemVariantUuid);

const deleteModifier = (modifierUuid) => menuManagerClient.deleteModifier(modifierUuid);

const deleteModifierGroup = (modifierGroupUuid) => menuManagerV2Client.deleteModifierGroup(modifierGroupUuid);

const createModifier = (modifier) => menuManagerClient.createModifier(modifier);

const updateModifier = (modifierUuid, modifierRequest) =>
    menuManagerClient.updateModifier(modifierUuid, modifierRequest);

const createModifierGroup = (modifierGroupRequest) =>
    menuManagerV2Client.createModifierGroup(snakeize(modifierGroupRequest));

const createVariant = ({ isDefault = false, ...rest }) =>
    menuManagerV2Client.createVariant(snakeize({ ...rest, isDefault }));

const createOrUpdateRestaurantMenu = (restaurantMenuRequest) =>
    menuManagerV2Client.createOrUpdateRestaurantMenu(snakeize(restaurantMenuRequest));

const updateModifierGroup = (modifierGroupUuid, modifierGroupUpdateRequest) =>
    menuManagerV2Client.updateModifierGroup(modifierGroupUuid, snakeize(modifierGroupUpdateRequest));

const updateVariant = (variantUuid, variantUpdateRequest) =>
    menuManagerV2Client.updateVariant(variantUuid, snakeize(variantUpdateRequest));

const uploadMenuItemVariantPicture = (menuItemUuid, menuItemVariantUuid, picture, basewidth = 1920) =>
    menuManagerV2Client.uploadMenuItemVariantPicture(menuItemUuid, menuItemVariantUuid, picture, basewidth);

const uploadMenuVariantPicture = (menuUuid, menuVariantUuid, picture, basewidth) =>
    menuManagerV2Client.uploadMenuVariantPicture(menuUuid, menuVariantUuid, picture, basewidth);

const createMenuItem = (menuItemRequest) => menuManagerV2Client.createMenuItem(snakeize(menuItemRequest));

const createMenuItemVariant = (menuItemUuid, menuItemVariantRequest) =>
    menuManagerV2Client.createMenuItemVariant(menuItemUuid, snakeize(menuItemVariantRequest));

const createMenuElement = (menuElementRequest) => menuManagerV2Client.createMenuElement(menuElementRequest);

const updateCategory = (categoryUuid, categoryUpdateRequest) =>
    menuManagerV2Client.updateCategory(categoryUuid, snakeize(categoryUpdateRequest));

const updateMenuElement = (menuElementUuid, menuElementUpdateRequest) =>
    menuManagerV2Client.updateMenuElement(menuElementUuid, menuElementUpdateRequest);

const updateMenuItem = (menuItemUuid, menuItemUpdateRequest) =>
    menuManagerV2Client.updateMenuItem(menuItemUuid, snakeize(menuItemUpdateRequest));

const updateMenuItemAvailabilities = (menuUuid, menuItemUuid, menuItemAvailabilityUpdateRequest) =>
    menuManagerV2Client.updateMenuItemAvailabilities(
        menuUuid,
        menuItemUuid,
        snakeize(menuItemAvailabilityUpdateRequest)
    );

const updateMenuItemDetail = (menuItemUuid, menuItemDetailUuid, menuItemDetailUpdateRequest) =>
    menuManagerV2Client.updateMenuItemDetail(menuItemUuid, menuItemDetailUuid, snakeize(menuItemDetailUpdateRequest));

const updateMenuVariant = (menuUuid, menuVariantUuid, menuVariantUpdateRequest) =>
    menuManagerV2Client.updateMenuVariant(menuUuid, menuVariantUuid, menuVariantUpdateRequest);

const fetchRestaurantMenus = ({ conceptUuid, platformUuid, locationUuid, menuUuid, isDeleted = false } = {}) => {
    return menuManagerClient.listRestaurantMenus({
        conceptUuid,
        platformUuid,
        locationUuid,
        menuUuid,
        isDeleted,
    });
};

const fetchRestaurantMenusStatus = (requestPayload) => menuManagerV2Client.listRestaurantMenusStatus(requestPayload);

const fetchVariants = ({ isDeleted = false, ...rest }) => menuManagerV2Client.listVariants({ ...rest, isDeleted });

const importMenuItems = (menuUuid, menuItemImportRequest) =>
    menuManagerV2Client.importMenuItems(menuUuid, snakeize(menuItemImportRequest));

const fetchMenus = ({ conceptUuid, isDeleted = false, label } = {}) => {
    return menuManagerV2Client.listMenus({ conceptUuid, isDeleted, label });
};

const fetchMenusOverview = ({ conceptUuid, isDeleted = false, loadUsage = false } = {}) => {
    return menuManagerV2Client.listMenusOverview({ conceptUuid, isDeleted, loadUsage });
};

const fetchMenuItemDetails = (menuItemUuid, { isDeleted = false, ...requestPayload } = {}) => {
    return menuManagerV2Client.listMenuItemDetails(menuItemUuid, { ...requestPayload, isDeleted });
};

const fetchMenuItemVariants = (menuItemUuid, { conceptUuid, variantUuid, isDeleted = false } = {}) => {
    return menuManagerV2Client.listMenuItemVariants(menuItemUuid, { conceptUuid, variantUuid, isDeleted });
};

const updateMenu = (menuUuid, menuUpdateRequest) => {
    return menuManagerV2Client.updateMenu(menuUuid, menuUpdateRequest);
};

const createCategory = (categoryRequest) => {
    return menuManagerV2Client.createCategory(snakeize(categoryRequest));
};

export const menuManagerApi = {
    createCategory,
    fetchMenuItemNutritionalClassifications,
    createMenu,
    createMenuElement,
    createMenuItem,
    createMenuItemVariant,
    createModifier,
    createModifierGroup,
    createVariant,
    createOrUpdateRestaurantMenu,
    deleteCategory,
    deleteMenuItemVariant,
    deleteModifier,
    deleteModifierGroup,
    fetchCategories,
    fetchCategoriesDetails,
    fetchCategoriesVariants,
    fetchCategoryDetails,
    fetchGeneratedMenu,
    fetchGeneratedMenus,
    fetchMenu,
    fetchMenuDetails,
    fetchMenuElementCustomerDisplay,
    fetchMenuElementOneTabletDisplay,
    fetchMenuElements,
    fetchMenuItem,
    fetchMenuItemAvailabilities,
    fetchMenuItemCategories,
    fetchMenuItemClassifications,
    fetchMenuItemDetails,
    fetchMenuItemLibrary,
    fetchMenuItemVariants,
    fetchMenuItems,
    fetchMenuItemsDetails,
    fetchMenuItemsOverview,
    fetchMenuItemsVariants,
    fetchMenuVariants,
    fetchMenus,
    fetchMenusOverview,
    fetchModifierDetails,
    fetchModifierGroupDetails,
    fetchModifierGroups,
    fetchModifierGroupsDetails,
    fetchModifierGroupsVariants,
    fetchModifierVariants,
    fetchModifiers,
    fetchModifiersVariants,
    fetchRestaurantMenus,
    fetchRestaurantMenusStatus,
    fetchVariants,
    importMenuItems,
    updateCategory,
    updateMenu,
    updateMenuElement,
    updateMenuItem,
    updateMenuItemAvailabilities,
    updateMenuItemDetail,
    updateMenuVariant,
    updateModifier,
    updateModifierGroup,
    updateVariant,
    uploadMenuItemVariantPicture,
    uploadMenuVariantPicture,
};
