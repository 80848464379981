import actions from '@/store/modules/status/actions';
import getters from '@/store/modules/status/getters';
import mutations from '@/store/modules/status/mutations';

export default {
    namespaced: true,
    state: {},
    actions,
    getters,
    mutations,
};
