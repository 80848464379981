import clientHook from '@/apis';

const OrdersClient = require('@tasterkitchens/orders-client');

const ordersApi = new OrdersClient.OrdersApi();

const ordersUrl = process.env.VUE_APP_ORDERS_ENDPOINT;
ordersApi.apiClient.basePath = ordersUrl;

export default {
    fetchOrders(number, day, locationUuid, conceptUuid, platformUuid, summary) {
        return clientHook(ordersApi).appApisV1OrdersListOrders({
            _number: number,
            day: day,
            locationUuid: locationUuid,
            conceptUuid: conceptUuid,
            platformUuid: platformUuid,
            summary: summary,
        });
    },
    fetchOrderByUuid(uuid) {
        return clientHook(ordersApi).appApisV1OrdersGetOrder(uuid);
    },
};
