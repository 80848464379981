import snakeize from 'snakeize';
import { reviewsApi } from './clients';

export default {
    fetchReviews(
        startDate,
        endDate,
        { page = 1, perPage = 10, locationUuids, conceptUuids, countryCode, orderBy, desc, platformUuids }
    ) {
        return reviewsApi.searchReviews(
            snakeize({
                startDate,
                endDate,
                page,
                perPage,
                locationUuids,
                conceptUuids,
                platformUuids,
                countryCode,
                orderBy,
                desc,
            })
        );
    },
};
