const getSummary = (state) => state.summary;
const getSplitChartByConcept = (state) => state.splitChartByConcept;
const getTimeseriesByConcept = (state) => state.timeseriesByConcept;
const getTimeseriesByLocation = (state) => state.timeseriesByLocation;

export default {
    getSummary,
    getSplitChartByConcept,
    getTimeseriesByConcept,
    getTimeseriesByLocation,
};
