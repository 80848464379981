import { locationsApi } from './clients';

export default {
    fetchLiveStatuses() {
        return locationsApi.listLiveStatuses();
    },
    fetchLiveStatusByLocationUuid(locationUuid) {
        return locationsApi.getLiveStatus(locationUuid);
    },
};
