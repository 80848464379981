import {
    SET_QUALITY_SPLIT_CHART_BY_CONCEPT,
    SET_QUALITY_SUMMARY,
    SET_QUALITY_TIME_SERIES_BY_CONCEPT,
    SET_QUALITY_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/quality/mutationTypes';

export default {
    [SET_QUALITY_SPLIT_CHART_BY_CONCEPT]: (state, splitChartByConcept) => {
        state.splitChartByConcept = splitChartByConcept;
    },
    [SET_QUALITY_SUMMARY]: (state, summary) => {
        state.summary = summary;
    },
    [SET_QUALITY_TIME_SERIES_BY_CONCEPT]: (state, timeseriesByConcept) => {
        state.timeseriesByConcept = timeseriesByConcept;
    },
    [SET_QUALITY_TIME_SERIES_BY_LOCATION]: (state, timeseriesByLocation) => {
        state.timeseriesByLocation = timeseriesByLocation;
    },
};
