<template>
    <v-snackbar v-model="showSnackbar" :top="top" :bottom="bottom" :timeout="timeout" :class="snackbarClass">
        <div :class="contentClass">
            <v-icon class="snackbar-alert__icon">
                {{ icon }}
            </v-icon>
            <div class="header">
                <span class="text-body-1 title">{{ message }}</span>
                <p v-if="subText.length" class="text-body-1">{{ subText }}</p>
            </div>
        </div>
        <template #action="{ attrs }">
            <v-btn v-bind="attrs" :color="color" type="button" icon aria-label="Close alert" @click="closeSnackbar">
                <v-icon>fa-solid fa-xmark </v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            message: '',
            subText: '',
            type: 'success',
            showSnackbar: false,
            position: 'top',
        };
    },
    computed: {
        timeout() {
            const timeoutMap = {
                error: 10000,
                success: 3000,
                warning: 5000,
            };

            return timeoutMap[this.type];
        },

        top() {
            return this.position === 'top';
        },

        bottom() {
            return this.position === 'bottom';
        },

        icon() {
            const iconMap = {
                error: 'fa-solid fa-circle-exclamation',
                success: 'check_circle',
                warning: 'fa-solid fa-circle-exclamation',
            };

            return iconMap[this.type];
        },

        snackbarClass() {
            const classMap = {
                error: 'snackbar-alert--error',
                success: 'snackbar-alert--success',
                warning: 'snackbar-alert--warning',
            };

            return classMap[this.type];
        },

        contentClass() {
            const classMap = {
                error: 'snackbar-alert__content--error',
                success: 'snackbar-alert__content--success',
                warning: 'snackbar-alert__content--warning',
            };

            return `snackbar-alert__content ${classMap[this.type]}`;
        },

        color() {
            const colorMap = {
                error: 'hsl(359, 100%, 66%)',
                success: '#12B76A',
                warning: 'hsl(28, 100%, 57%)',
            };

            return colorMap[this.type];
        },
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'notifier/SHOW_NOTIFICATION') {
                const { message, subText = '', type, position } = state.notifier;
                this.message = message;
                this.subText = subText;
                this.type = type;
                this.showSnackbar = true;
                if (position) this.position = position;
            }
        });
    },
    methods: {
        closeSnackbar() {
            this.showSnackbar = false;
        },
    },
};
</script>

<style scoped lang="scss">
.snackbar-alert__content {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-size: 1rem;
    font-weight: 400;

    & .title {
        font-weight: 500;
    }

    & .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
    }
}

.snackbar-alert__content--error {
    color: var(--v-error-base);
}

.snackbar-alert__content--success {
    color: var(--v-success-base);
}

.snackbar-alert__content--warning {
    color: var(--v-warning-base);
}

::v-deep .theme--dark.v-icon {
    color: currentColor;
}

::v-deep .v-sheet.v-snack__wrapper {
    border-radius: 8px;
    border: 1px solid;
}

.snackbar-alert--error ::v-deep .v-sheet.v-snack__wrapper {
    border-color: var(--v-error-base) !important;
    background-color: var(--v-error-lighter-base);
}

.snackbar-alert--success ::v-deep .v-sheet.v-snack__wrapper {
    border-color: var(--v-success-light-base) !important;
    background-color: var(--v-success-lighter-base);
}

.snackbar-alert--warning ::v-deep .v-sheet.v-snack__wrapper {
    border-color: var(--v-warning-light-base) !important;
    background-color: var(--v-warning-lighter-base);
}
</style>
