import Vue from 'vue';

export const groups = {
    Accountant: 'Accountant',
    Engineers: 'Engineers',
    InvoicesBetaTester: 'Beta Tester Invoices',
    MenuOwner: 'Menu Owner',
    T4P: 'T4P',
    NewOrderingProcess: '[Beta][Supply] New Ordering Process',
    SupplyMailbox: '[Alpha][Supply] Messaging',
};

export const hasGroup = (group) => Vue.prototype.$auth.userGroups.includes(group);
