import StackdriverErrorReporter from 'stackdriver-errors-js';

class ErrorReporting {
    errorReporter;

    constructor(stackdriverOptions) {
        this.initHandler(stackdriverOptions);
    }

    initHandler(stackdriverOptions) {
        this.errorReporter = new StackdriverErrorReporter();
        this.errorReporter.start({
            ...stackdriverOptions,
            disabled: process.env.NODE_ENV !== 'production',
        });
    }

    onError(errorObject) {
        this.errorReporter.report(errorObject.error ?? errorObject);
        console.error(errorObject);
    }
}

export default {
    install(Vue, stackdriverOptions) {
        const errorReporting = new ErrorReporting(stackdriverOptions);

        Vue.config.errorHandler = (error) => errorReporting.onError(error);
        Vue.prototype.$errorReporting = errorReporting;
    },
};
