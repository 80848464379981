import { roles } from './roles';

const OpeningHoursHomepage = () => import(/* webpackChunkName: "one-board" */ '@/views/OpeningHours.vue');

const EditSpecialHoursDialog = () =>
    import(/* webpackChunkName: "one-board" */ '@/components/OpeningHours/EditSpecialHoursDialog.vue');

const EditOpeningHoursDialog = () =>
    import(/* webpackChunkName: "one-board" */ '@/components/OpeningHours/EditOpeningHoursDialog.vue');

export default {
    path: '/opening-hours/:kitchenUuid?/:conceptUuid?',
    name: 'openingHours',
    keyName: 'openingHours',
    component: OpeningHoursHomepage,
    icon: 'fa-solid fa-clock',
    display: true,
    meta: { authorize: { roles: [roles.OpeningsViewer, roles.RestaurantLiveViewer] }, title: 'menu.openingHours' },
    hasMobileTabLabel: true,
    props: true,
    children: [
        {
            path: 'special-hours-dialog',
            name: 'specialHoursDialog',
            meta: { authorize: { roles: [roles.SpecialHoursViewer, roles.RestaurantLiveViewer] } },
            components: {
                specialHoursDialog: EditSpecialHoursDialog,
            },
        },
        {
            path: 'opening-hours-dialog',
            name: 'openingHoursDialog',
            meta: { authorize: { roles: [roles.OpeningsViewer, roles.RestaurantStateController] } },
            components: {
                openingHoursDialog: EditOpeningHoursDialog,
            },
        },
    ],
};
