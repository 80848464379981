import { isEmpty, keyBy, chain } from 'lodash';

export const getValueByCountryCode = (list, countryCodeToFind) => {
    const item = list.find(({ country_code }) => country_code === countryCodeToFind);
    return item ? item.value : '';
};

export const generateDetailsByPlatformUuids = (platformUuids = [], locationUuid, entitiesToCreate = []) =>
    platformUuids.map((platformUuid) => {
        const detail = {
            location_uuid: locationUuid,
            platform_uuid: platformUuid,
        };
        entitiesToCreate.forEach(({ key, value }) => (detail[key] = value));
        return detail;
    });

export const updateDetailsWithEntities = (details = [], entitiesToUpdate = []) =>
    details.map((detail) => {
        const detailToUpdate = { ...detail };
        entitiesToUpdate.forEach(({ key, value }) => (detailToUpdate[key] = value));
        return detailToUpdate;
    });

export const updateDetailByCountryCode = (details, detailValue, countryCode) => {
    const foundDetailForCountryCode = details.some(({ country_code }) => country_code === countryCode);

    if (foundDetailForCountryCode) {
        return details.map((detail) =>
            detail.country_code === countryCode ? { value: detailValue, country_code: countryCode } : detail
        );
    }
    return [...details, { value: detailValue, country_code: countryCode }];
};

export const setElementsPositions = (elements = []) =>
    elements.map((element, index) => ({ ...element, position: index }));

export const buildMenuItemRequest = ({
    categoryUuid,
    classificationUuid,
    conceptUuid,
    descriptionDetail,
    displayOptions,
    label,
    labelDetail,
    languageCode,
    menuCategoryUuid,
    menuItemType,
    modifierGroups,
    sellingCurrency,
    sellingPrice,
    variantUuid,
    vat,
    nutritionalInfo,
}) => {
    const menuItemRequest = {
        menuCategoryUuid,
        categoryUuid,
        classificationUuid,
        conceptUuid,
        descriptions: [{ countryCode: languageCode, value: descriptionDetail }],
        displayOptions,
        label,
        labels: [{ countryCode: languageCode, value: labelDetail }],
        menuItemType,
        sellingPrice,
        sellingCurrency,
        vat,
        variantUuid,
        nutritionalInfo,
    };

    if (modifierGroups) {
        menuItemRequest.modifierGroups = setElementsPositions(modifierGroups);
    }

    return menuItemRequest;
};

export const buildMenuItemUpdateRequest = ({
    categoryUuid,
    classificationUuid,
    descriptionDetail,
    label,
    labelDetail,
    languageCode,
    menuItemVariant,
    sellingPrice,
    variantUuid,
    vat,
    nutritionalInfo,
}) => {
    const menuItemUpdateRequest = {
        categoryUuid,
        classificationUuid,
        label,
        sellingPrice,
        variantUuid,
        vat,
        nutritionalInfo,
    };

    const { labels = [], descriptions = [] } = menuItemVariant;
    if (labelDetail && languageCode) {
        menuItemUpdateRequest.labels = updateDetailByCountryCode(labels, labelDetail, languageCode);
    }
    if (languageCode) {
        menuItemUpdateRequest.descriptions = updateDetailByCountryCode(descriptions, descriptionDetail, languageCode);
    }

    return isEmpty(menuItemUpdateRequest) ? null : menuItemUpdateRequest;
};

export const buildMenuElementRequest = ({ menuItemUuid, modifierGroups = [], categoryUuid }) => {
    return {
        category_uuid: categoryUuid,
        menu_item_uuid: menuItemUuid,
        modifier_groups: setElementsPositions(modifierGroups),
    };
};

const buildModifiersRequest = (modifiers, sellingCurrency) =>
    modifiers.map(({ menuItemUuid, sellingPrice, uuid, modifierVariantUuid }, index) => ({
        menuItemUuid,
        modifierUuid: uuid,
        modifierVariantUuid,
        position: index,
        sellingCurrency,
        sellingPrice,
    }));

export const buildModifierGroupRequest = ({
    description,
    included,
    label,
    languageCode,
    max,
    menuUuid,
    modifiers = [],
    sellingCurrency,
    variantLabel,
    variantUuid,
}) => {
    const modifierGroupRequest = {
        included,
        label,
        max,
        menuUuid,
        variantUuid,
    };

    if (variantLabel && languageCode) {
        modifierGroupRequest.labels = [{ countryCode: languageCode, value: variantLabel }];
    }
    if (description && languageCode) {
        modifierGroupRequest.descriptions = [{ countryCode: languageCode, value: description }];
    }
    if (modifiers.length) {
        modifierGroupRequest.modifiers = buildModifiersRequest(modifiers, sellingCurrency);
    }

    return modifierGroupRequest;
};

const buildUpdateModifiersRequest = (modifiers, sellingCurrency) =>
    setElementsPositions(modifiers).reduce(
        (modifiersRequestAcc, { menuItemUuid, sellingPrice, uuid, modifierVariantUuid, position }) => {
            if (uuid) {
                modifiersRequestAcc.existingModifiers = [
                    ...modifiersRequestAcc.existingModifiers,
                    {
                        modifierUuid: uuid,
                        modifierVariantUuid,
                        position,
                        sellingCurrency,
                        sellingPrice,
                    },
                ];
            } else {
                modifiersRequestAcc.newModifiers = [
                    ...modifiersRequestAcc.newModifiers,
                    {
                        menuItemUuid,
                        position,
                        sellingCurrency,
                        sellingPrice,
                    },
                ];
            }

            return modifiersRequestAcc;
        },
        { existingModifiers: [], newModifiers: [] }
    );

export const buildModifierGroupUpdateRequest = ({
    description,
    included,
    label,
    languageCode,
    max,
    modifierGroupVariant,
    modifiers,
    sellingCurrency,
    variantLabel,
    variantUuid,
}) => {
    const modifierGroupUpdateRequest = {
        included,
        label,
        max,
        modifierGroupVariantUuid: modifierGroupVariant.uuid,
        variantUuid,
    };

    const { labels = [], descriptions = [] } = modifierGroupVariant;
    if (variantLabel && languageCode) {
        modifierGroupUpdateRequest.labels = updateDetailByCountryCode(labels, variantLabel, languageCode);
    }
    if (languageCode) {
        modifierGroupUpdateRequest.descriptions = updateDetailByCountryCode(descriptions, description, languageCode);
    }
    if (modifiers.length) {
        const { existingModifiers, newModifiers } = buildUpdateModifiersRequest(modifiers, sellingCurrency);
        modifierGroupUpdateRequest.existingModifiers = existingModifiers;
        modifierGroupUpdateRequest.newModifiers = newModifiers;
    }

    return modifierGroupUpdateRequest;
};

export const hasHeroImage = (menuVariant) => !!menuVariant.pictures.formatted;

export const hasDescription = (menuVariant, countryCode) =>
    menuVariant.descriptions ? !!getValueByCountryCode(menuVariant.descriptions, countryCode).trim() : false;

export const hasAtLeastOneCategory = (menu) => !!menu.categories.length;

export const hasNoEmptyCategories = (categories = []) => {
    if (!categories.length) {
        return false;
    }

    return categories.every(({ menu_elements }) => !!menu_elements.length);
};

export const isMenuItemNestedInModifierGroup = (menuItemUuid, modifierGroup, modifiers) => {
    const modifierMap = keyBy(modifiers, 'uuid');
    return modifierGroup.modifiers.some(
        ({ modifier_uuid }) => modifierMap[modifier_uuid] && modifierMap[modifier_uuid].menu_item_uuid === menuItemUuid
    );
};

export const getSubModifierGroupsFromMenuElement = (menuElement) => {
    return menuElement.modifier_groups
        .filter(({ is_available_in_parent_modifier_group }) => is_available_in_parent_modifier_group)
        .map(({ modifier_group_uuid }) => modifier_group_uuid);
};

export const buildMenuElementCustomerDisplayInputMap = (modifiersGroups) => {
    return chain(modifiersGroups)
        .keyBy('modifierGroupUuid')
        .mapValues((currentModifierGroup) => {
            return {
                ...currentModifierGroup,
                quantity: 0,
                modifiers: chain(currentModifierGroup.modifiers)
                    .keyBy('modifierUuid')
                    .mapValues((modifier) => {
                        return {
                            ...modifier,
                            quantity: 0,
                            modifierGroups: chain(modifier.modifierGroups)
                                .keyBy('modifierGroupUuid')
                                .mapValues((subModifierGroup) => {
                                    return {
                                        ...subModifierGroup,
                                        modifiers: chain(subModifierGroup.modifiers)
                                            .keyBy('modifierUuid')
                                            .mapValues((submodifier) => ({ ...submodifier, quantity: 0 }))
                                            .value(),
                                    };
                                })
                                .value(),
                        };
                    })
                    .value(),
            };
        })
        .value();
};
