import { getPublishedInvoices } from '@/apis/invoices';
import { SET_PUBLISHED_INVOICES } from '@/store/modules/invoices/mutationTypes';

const fetchPublishedInvoices = async ({ commit, dispatch }, payload = {}) => {
    dispatch('status/setLoadingStatus', { stateKey: 'publishedInvoices', isLoading: true }, { root: true });
    try {
        const { data: publishedInvoices } = await getPublishedInvoices(payload);
        commit(SET_PUBLISHED_INVOICES, publishedInvoices);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'publishedInvoices', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'publishedInvoices', isLoading: false }, { root: true });
    }
};

export default { fetchPublishedInvoices };
