import {
    ADD_CATEGORY_TO_MENU,
    ADD_MENU_ELEMENT_TO_CATEGORY,
    SET_NUTRITIONAL_CLASSIFICATIONS,
    SET_CATEGORIES,
    SET_CATEGORIES_VARIANTS,
    SET_CATEGORY,
    SET_CATEGORY_DETAILS,
    SET_CATEGORY_VARIANT,
    SET_MENU,
    SET_MENU_DETAILS,
    SET_MENU_ELEMENT,
    SET_MENU_ELEMENTS,
    SET_MENU_ITEM,
    SET_MENU_ITEM_CATEGORIES,
    SET_MENU_ITEM_CLASSIFICATIONS,
    SET_MENU_ITEM_DETAILS,
    SET_MENU_ITEM_VARIANT,
    SET_MENU_ITEMS,
    SET_MENU_ELEMENT_CUSTOMER_DISPLAY,
    SET_MENU_ITEMS_VARIANTS,
    SET_MENU_REQUEST_HELPERS,
    SET_MENU_VARIANT,
    SET_MENUS_VARIANTS,
    SET_MODIFIER,
    SET_MODIFIER_DETAILS,
    SET_MODIFIER_GROUP,
    SET_MODIFIER_GROUP_DETAILS,
    SET_MODIFIER_GROUP_VARIANT,
    SET_MODIFIER_GROUPS,
    SET_MODIFIER_GROUPS_VARIANTS,
    SET_MODIFIERS,
    SET_MODIFIERS_VARIANTS,
    SET_RESTAURANT_MENUS,
    SET_RESTAURANT_STATES,
    SET_VARIANT,
    SET_VARIANTS,
    UPDATE_CATEGORY,
    UPDATE_MENU_ITEM_VARIANT,
    UPDATE_MODIFIER_DETAIL,
    DELETE_MODIFIER_GROUP,
} from '@/store/modules/menuManager/mutationTypes';
import { isEmpty, keyBy } from 'lodash';
import { menuManagerApi } from '@/apis/menuManagerApi.js';
import {
    buildMenuElementRequest,
    buildMenuItemRequest,
    buildMenuItemUpdateRequest,
    buildModifierGroupRequest,
    buildModifierGroupUpdateRequest,
    generateDetailsByPlatformUuids,
    getValueByCountryCode,
    setElementsPositions,
    updateDetailByCountryCode,
} from '@/utils/menuManagerHelpers';
import { platformStateApi } from '@/apis/platformStateApi';
import camelize from 'camelize';

const fetchMenuItemNutritionalClassifications = async ({ getters, commit }) => {
    if (Object.values(getters.getNutritionalClassifications).length) return getters.getNutritionalClassifications;

    const nutritionalClassifications = await menuManagerApi.fetchMenuItemNutritionalClassifications();

    commit(SET_NUTRITIONAL_CLASSIFICATIONS, nutritionalClassifications);

    return nutritionalClassifications;
};

const fetchCategories = async ({ commit }, { menuUuid }) => {
    const categories = await menuManagerApi.fetchCategories({ menuUuid });
    commit(SET_CATEGORIES, categories);

    return categories;
};

const fetchCategoriesDetails = async ({ commit }, payload) => {
    const categoriesDetails = await menuManagerApi.fetchCategoriesDetails(payload);
    commit(SET_CATEGORY_DETAILS, categoriesDetails);
    return categoriesDetails;
};

const fetchCategoriesVariants = async ({ commit }, payload) => {
    const categoriesVariants = await menuManagerApi.fetchCategoriesVariants(payload);
    commit(SET_CATEGORIES_VARIANTS, categoriesVariants);

    return categoriesVariants;
};

const fetchCategoryDetails = async ({ commit }, { categoryUuid }) => {
    const categoryDetails = await menuManagerApi.fetchCategoryDetails(categoryUuid);
    commit(SET_CATEGORY_DETAILS, categoryDetails);
    return categoryDetails;
};

const fetchEntireMenu = async ({ dispatch }, { conceptUuid, menuUuid }) => {
    const menu = await dispatch('fetchMenu', { menuUuid });
    const responses = await Promise.all([
        dispatch('fetchCategories', { menuUuid }),
        dispatch('fetchCategoriesVariants', { variantUuid: menu.default_variant_uuid }),
        dispatch('fetchMenuElements', { menuUuid }),
        dispatch('fetchMenuItems', { conceptUuid }),
        dispatch('fetchMenuItemsVariants', { conceptUuid, variantUuid: menu.default_variant_uuid }),
        dispatch('fetchMenuVariants', { menuUuid, variantUuid: menu.default_variant_uuid }),
        dispatch('fetchModifierGroups', { menuUuid, loadUsage: true }),
        dispatch('fetchModifierGroupsVariants', { variantUuid: menu.default_variant_uuid }),
        dispatch('fetchModifiers', { conceptUuid }),
        dispatch('fetchModifiersVariants', menu.default_variant_uuid),
        dispatch('fetchVariants', { menuUuid }),
    ]);

    return [menu, ...responses];
};

const fetchMenu = async ({ commit }, { menuUuid }) => {
    const menu = await menuManagerApi.fetchMenu(menuUuid);
    commit(SET_MENU, menu);
    return menu;
};

const fetchMenuDetails = async ({ commit }, { menuUuid }) => {
    const menuDetails = await menuManagerApi.fetchMenuDetails(menuUuid);
    commit(SET_MENU_DETAILS, menuDetails);
    return menuDetails;
};

const updateMenuElementsPosition = ({ commit }, { categoryUuid, categoryMenuElements }) => {
    const categoryUpdatePayload = {
        menu_elements: setElementsPositions(categoryMenuElements),
    };
    commit(UPDATE_CATEGORY, { categoryUuid, categoryUpdatePayload });
};

const uploadMenuPicture = async ({ commit }, { menuUuid, menuVariantUuid, picture, basewidth = 1920 }) => {
    const menuVariant = await menuManagerApi.uploadMenuVariantPicture(menuUuid, menuVariantUuid, picture, basewidth);
    commit(SET_MENU_VARIANT, menuVariant);

    return menuVariant;
};

const uploadMenuItemPicture = async ({ commit }, { menuItemUuid, menuItemVariantUuid, picture, basewidth = 1920 }) => {
    const menuItemVariant = await menuManagerApi.uploadMenuItemVariantPicture(
        menuItemUuid,
        menuItemVariantUuid,
        picture,
        basewidth
    );

    commit(SET_MENU_ITEM_VARIANT, menuItemVariant);

    return menuItemVariant;
};

const setMenuRequestHelpers = ({ commit }, payload) => {
    commit(SET_MENU_REQUEST_HELPERS, payload);
};

const swapCategoryPosition = ({ commit, getters }, { menuUuid, newPosition, oldPosition }) => {
    const menu = getters.getMenu(menuUuid);
    const menuCategories = [...menu.categories];

    if (newPosition > -1 && newPosition < menuCategories.length) {
        [menuCategories[oldPosition], menuCategories[newPosition]] = [
            menuCategories[newPosition],
            menuCategories[oldPosition],
        ];
        commit(SET_MENU, { ...menu, categories: setElementsPositions(menuCategories) });
    }
};

const fetchMenuElementCustomerDisplay = async ({ commit }, { menuElementUuid }) => {
    const menuElementDisplay = await menuManagerApi.fetchMenuElementCustomerDisplay(menuElementUuid);

    commit(SET_MENU_ELEMENT_CUSTOMER_DISPLAY, camelize(menuElementDisplay));

    return menuElementDisplay;
};

const fetchMenuElements = async ({ commit }, payload) => {
    const menuElements = await menuManagerApi.fetchMenuElements(payload);
    commit(SET_MENU_ELEMENTS, menuElements);
    return menuElements;
};

const fetchMenuItem = async ({ commit }, menuItemUuid) => {
    const menuItem = await menuManagerApi.fetchMenuItem(menuItemUuid);
    commit(SET_MENU_ITEM, menuItem);

    return menuItem;
};

const fetchMenuItemCategories = async ({ commit }, requestPayload) => {
    const menuItemCategories = await menuManagerApi.fetchMenuItemCategories(requestPayload);
    commit(SET_MENU_ITEM_CATEGORIES, menuItemCategories);
};

const fetchMenuItemClassifications = async ({ commit }, requestPayload) => {
    const menuItemClassifications = await menuManagerApi.fetchMenuItemClassifications(requestPayload);
    const menuItemClassificationsWithMappedVat = menuItemClassifications.map((classification) => ({
        ...classification,
        vat: keyBy(classification.vat, 'country_code'),
    }));
    commit(SET_MENU_ITEM_CLASSIFICATIONS, menuItemClassificationsWithMappedVat);
};

const fetchMenuItemDetails = async ({ commit }, { menuItemUuid, ...requestPayload }) => {
    const menuItemDetails = await menuManagerApi.fetchMenuItemDetails(menuItemUuid, requestPayload);
    commit(SET_MENU_ITEM_DETAILS, menuItemDetails);
    return menuItemDetails;
};

const fetchMenuItemVariants = async ({ commit }, { menuItemUuid, ...requestPayload }) => {
    const menuItemVariants = await menuManagerApi.fetchMenuItemVariants(menuItemUuid, requestPayload);
    commit(SET_MENU_ITEMS_VARIANTS, menuItemVariants);

    return menuItemVariants;
};

const fetchMenuItems = async ({ commit }, payload) => {
    const menuItems = await menuManagerApi.fetchMenuItems(payload);
    commit(SET_MENU_ITEMS, menuItems);
    return menuItems;
};

const fetchMenuItemsDetails = async ({ commit }, payload) => {
    const menuItemsDetails = await menuManagerApi.fetchMenuItemsDetails(payload);
    commit(SET_MENU_ITEM_DETAILS, menuItemsDetails);
    return menuItemsDetails;
};

const fetchMenuItemsVariants = async ({ commit }, payload) => {
    const menuItemsVariants = await menuManagerApi.fetchMenuItemsVariants(payload);
    commit(SET_MENU_ITEMS_VARIANTS, menuItemsVariants);
    return menuItemsVariants;
};

const fetchMenuVariants = async ({ commit }, { menuUuid, variantUuid }) => {
    const menuVariants = await menuManagerApi.fetchMenuVariants(menuUuid, { variantUuid });
    commit(SET_MENUS_VARIANTS, menuVariants);
    return menuVariants;
};

const createModifier = async ({ commit, dispatch, getters }, { menuItemUuid, modifierGroupUuid, sellingPrice }) => {
    const { platformUuids, locationUuid } = getters.getMenuRequestHelpers;
    const modifierRequest = {
        menu_item_uuid: menuItemUuid,
        modifier_group_uuid: modifierGroupUuid,
        details: generateDetailsByPlatformUuids(platformUuids, locationUuid, [
            { key: 'selling_price', value: sellingPrice },
        ]),
    };
    const modifier = await menuManagerApi.createModifier(modifierRequest);
    await dispatch('fetchModifierDetails', { modifierUuid: modifier.uuid });
    commit(SET_MODIFIER, modifier);
    return modifier;
};

const updateModifier = async ({ commit, getters }, { modifierUuid, sellingPrice }) => {
    const { platformUuids, locationUuid } = getters.getMenuRequestHelpers;
    const modifierDetails = getters.getModifierDetails(modifierUuid, locationUuid);

    const modifierRequest = {};
    const modifierDetailUpdatePayload = {};
    if (typeof sellingPrice === 'number') {
        modifierRequest.details = generateDetailsByPlatformUuids(platformUuids, locationUuid, [
            { key: 'selling_price', value: sellingPrice },
        ]);
        modifierDetailUpdatePayload.selling_price = sellingPrice;
    }

    const modifier = await menuManagerApi.updateModifier(modifierUuid, modifierRequest);
    modifierDetails.forEach(({ uuid }) =>
        commit(UPDATE_MODIFIER_DETAIL, { modifierDetailUuid: uuid, modifierDetailUpdatePayload })
    );
    commit(SET_MODIFIER, modifier);
    return modifier;
};

const deleteModifier = async (context, modifierUuid) => {
    await menuManagerApi.deleteModifier(modifierUuid);
};

const deleteModifierGroup = async ({ commit }, modifierGroupUuid) => {
    await menuManagerApi.deleteModifierGroup(modifierGroupUuid);

    commit(DELETE_MODIFIER_GROUP, modifierGroupUuid);
};

const createModifierGroup = async (
    { commit },
    { menuUuid, description, included, languageCode, max, modifiers, variantUuid, sellingCurrency, variantLabel }
) => {
    const modifierGroupRequest = buildModifierGroupRequest({
        description,
        included,
        languageCode,
        max,
        menuUuid,
        modifiers,
        sellingCurrency,
        variantLabel,
        variantUuid,
    });

    const response = await menuManagerApi.createModifierGroup({
        ...modifierGroupRequest,
        label: variantLabel,
    });
    commit(SET_MENU_ITEMS_VARIANTS, response.menu_item_variants);
    commit(SET_MODIFIERS, response.modifiers);
    commit(SET_MODIFIERS_VARIANTS, response.modifier_variants);
    commit(SET_MODIFIER_GROUP, response.modifier_group);
    commit(SET_MODIFIER_GROUP_VARIANT, response.modifier_group_variant);

    return {
        menuItemCVariants: response.menu_item_variants,
        modifierGroup: response.modifier_group,
        modifierGroupVariant: response.modifier_group_variant,
        modifierVariants: response.modifier_variants,
        modifiers: response.modifiers,
    };
};

const createVariant = async ({ commit }, payload) => {
    const variant = await menuManagerApi.createVariant(payload);
    commit(SET_VARIANT, variant);

    return variant;
};

const updateVariant = async ({ commit }, { variantUuid, ...variantUpdateRequest }) => {
    const variant = await menuManagerApi.updateVariant(variantUuid, variantUpdateRequest);
    commit(SET_VARIANT, variant);

    return variant;
};

const updateModifierGroup = async (
    { commit },
    {
        description,
        included,
        languageCode,
        max,
        modifierGroupUuid,
        modifierGroupVariant,
        modifiers,
        sellingCurrency,
        variantLabel,
        variantUuid,
    }
) => {
    let modifierGroupUpdateRequest = buildModifierGroupUpdateRequest({
        description,
        included,
        label: variantLabel,
        languageCode,
        max,
        modifierGroupVariant,
        modifiers,
        sellingCurrency,
        variantLabel,
        variantUuid,
    });

    const response = await menuManagerApi.updateModifierGroup(modifierGroupUuid, modifierGroupUpdateRequest);
    commit(SET_MENU_ITEMS_VARIANTS, response.menu_item_variants);
    commit(SET_MODIFIERS, response.modifiers);
    commit(SET_MODIFIERS_VARIANTS, response.modifier_variants);
    commit(SET_MODIFIER_GROUP, response.modifier_group);
    commit(SET_MODIFIER_GROUP_VARIANT, response.modifier_group_variant);

    return {
        menuItemCVariants: response.menu_item_variants,
        modifierGroup: response.modifier_group,
        modifierGroupVariant: response.modifier_group_variant,
        modifierVariants: response.modifier_variants,
        modifiers: response.modifiers,
    };
};

const saveMenu = async ({ dispatch, getters }, { menuUuid }) => {
    const menuCategories = getters.getMenu(menuUuid).categories;
    await dispatch('updateMenu', { menuCategories, menuUuid });
    for (const { category_uuid } of menuCategories) {
        const { uuid, menu_elements } = getters.getCategory(category_uuid);
        await dispatch('updateCategory', { categoryUuid: uuid, menuElements: menu_elements });
    }
};

const createCategory = async ({ commit }, { languageCode, label, description, menuUuid, variantUuid }) => {
    const categoryRequest = {
        menuUuid,
        variantUuid,
    };

    if (label) {
        categoryRequest.labels = [{ countryCode: languageCode, value: label }];
    }
    if (description) {
        categoryRequest.descriptions = [{ countryCode: languageCode, value: description }];
    }

    const { category, category_variant } = await menuManagerApi.createCategory(categoryRequest);
    commit(SET_CATEGORY, category);
    commit(SET_CATEGORY_VARIANT, category_variant);
    commit(ADD_CATEGORY_TO_MENU, { categoryUuid: category.uuid, menuUuid });
};

const createMenuItem = async (
    { commit },
    {
        categoryUuid,
        classificationUuid,
        conceptUuid,
        descriptionDetail,
        displayOptions,
        label,
        labelDetail,
        languageCode,
        menuCategoryUuid,
        menuItemType,
        modifierGroups,
        sellingCurrency,
        sellingPrice,
        variantUuid,
        vat,
        nutritionalInfo,
    }
) => {
    const menuItemRequest = buildMenuItemRequest({
        categoryUuid,
        classificationUuid,
        conceptUuid,
        descriptionDetail,
        displayOptions,
        label,
        labelDetail,
        languageCode,
        menuCategoryUuid,
        menuItemType,
        modifierGroups,
        sellingCurrency,
        sellingPrice,
        variantUuid,
        vat,
        nutritionalInfo,
    });

    const {
        menu_item: menuItem,
        menu_item_variant: menuItemVariant,
        menu_element: menuElement,
    } = await menuManagerApi.createMenuItem(menuItemRequest);

    commit(SET_MENU_ITEM, menuItem);
    commit(SET_MENU_ITEM_VARIANT, menuItemVariant);

    if (menuElement) {
        commit(SET_MENU_ELEMENT, menuElement);
        commit(ADD_MENU_ELEMENT_TO_CATEGORY, { categoryUuid: menuCategoryUuid, menuElementUuid: menuElement.uuid });
    }

    return { menuItem, menuItemVariant, menuElement };
};

const createMenuItemVariant = async ({ commit }, { menuItemUuid, menuItemVariantRequest }) => {
    const menuItemVariant = await menuManagerApi.createMenuItemVariant(menuItemUuid, menuItemVariantRequest);
    commit(SET_MENU_ITEM_VARIANT, menuItemVariant);

    return menuItemVariant;
};

const createMenuElement = async ({ commit }, { menuItemUuid, modifierGroups = [], categoryUuid }) => {
    const menuElementRequest = buildMenuElementRequest({
        menuItemUuid,
        modifierGroups,
        categoryUuid,
    });
    const menuElement = await menuManagerApi.createMenuElement(menuElementRequest);
    commit(SET_MENU_ELEMENT, menuElement);
    commit(ADD_MENU_ELEMENT_TO_CATEGORY, { categoryUuid, menuElementUuid: menuElement.uuid });
    return menuElement;
};

const updateCategory = async (
    { commit, getters },
    { categoryUuid, languageCode, label, description, menuElements, variantUuid }
) => {
    const categoryUpdateRequest = {};

    if (menuElements) {
        categoryUpdateRequest.menuElements = setElementsPositions(menuElements);
    }

    if (variantUuid && languageCode) {
        categoryUpdateRequest.variantUuid = variantUuid;
        const { labels = [], descriptions = [] } =
            getters.getCategoryVariantByVariantUuid(categoryUuid, variantUuid) ?? {};

        const oldLabel = getValueByCountryCode(labels, languageCode);
        if (label && label !== oldLabel) {
            categoryUpdateRequest.labels = updateDetailByCountryCode(labels, label, languageCode);
        }

        const oldDescription = getValueByCountryCode(descriptions, languageCode);
        if (description !== oldDescription) {
            categoryUpdateRequest.descriptions = updateDetailByCountryCode(descriptions, description, languageCode);
        }
    }

    const { category, category_variant: categoryVariant } = await menuManagerApi.updateCategory(
        categoryUuid,
        categoryUpdateRequest
    );
    commit(SET_CATEGORY, category);
    if (categoryVariant) {
        commit(SET_CATEGORY_VARIANT, categoryVariant);
    }

    return { category, categoryVariant };
};

const deleteCategory = async ({ commit, getters }, { categoryUuid, menuUuid }) => {
    await menuManagerApi.deleteCategory(categoryUuid);

    const menu = getters.getMenu(menuUuid);
    const updatedMenuCategories = menu.categories.filter(({ category_uuid }) => category_uuid !== categoryUuid);
    commit(SET_MENU, { ...menu, categories: setElementsPositions(updatedMenuCategories) });
    commit(UPDATE_CATEGORY, { categoryUuid, categoryUpdatePayload: { is_deleted: true } });
};

const deleteMenuItemVariant = async ({ commit }, { menuItemUuid, menuItemVariantUuid }) => {
    await menuManagerApi.deleteMenuItemVariant(menuItemUuid, menuItemVariantUuid);
    commit(UPDATE_MENU_ITEM_VARIANT, { menuItemVariantUuid, menuItemVariantUpdatePayload: { is_deleted: true } });
};

export const updateMenu = async ({ commit }, { menuCategories, menuUuid }) => {
    const menuUpdateRequest = {};

    if (menuCategories) {
        menuUpdateRequest.categories = setElementsPositions(menuCategories);
    }

    const { menu } = await menuManagerApi.updateMenu(menuUuid, menuUpdateRequest);
    commit(SET_MENU, menu);

    return menu;
};

const updateMenuElement = async ({ commit }, { menuElementUuid, displayOptions, modifierGroups = [] }) => {
    const menuElementUpdateRequest = {
        modifier_groups: setElementsPositions(modifierGroups),
        display_options: displayOptions,
    };

    const menuElement = await menuManagerApi.updateMenuElement(menuElementUuid, menuElementUpdateRequest);

    commit(SET_MENU_ELEMENT, menuElement);

    return menuElement;
};

const updateMenuItem = async (
    { commit, getters },
    {
        categoryUuid,
        classificationUuid,
        descriptionDetail,
        label,
        labelDetail,
        languageCode,
        menuItemUuid,
        sellingPrice,
        variantUuid,
        vat,
        nutritionalInfo,
    }
) => {
    const menuItem = getters.getMenuItemByUuid(menuItemUuid);
    const menuItemVariant = getters.getMenuItemVariantByVariantUuid(menuItemUuid, variantUuid) ?? {};

    const menuItemUpdateRequest = buildMenuItemUpdateRequest({
        categoryUuid,
        classificationUuid,
        descriptionDetail,
        label,
        labelDetail,
        languageCode,
        menuItemVariant,
        sellingPrice,
        variantUuid,
        vat,
        nutritionalInfo,
    });

    if (isEmpty(menuItemUpdateRequest)) {
        return menuItem;
    }

    const { menu_item, menu_item_variant } = await menuManagerApi.updateMenuItem(menuItemUuid, menuItemUpdateRequest);
    if (menu_item) {
        commit(SET_MENU_ITEM, menu_item);
    }
    if (menu_item_variant) {
        commit(SET_MENU_ITEM_VARIANT, menu_item_variant);
    }

    return { menuItem: menu_item, menuItemVariant: menu_item_variant };
};

const updateMenuVariant = async (
    { commit, getters },
    { description, label, languageCode, menuUuid, menuVariantUuid }
) => {
    const menuVariant = getters.getMenuVariantByUuid(menuVariantUuid) ?? {};
    const { labels = [], descriptions = [] } = menuVariant;
    const menuVariantUpdateRequest = {};

    const oldLabel = getValueByCountryCode(labels, languageCode);
    if (label && label !== oldLabel) {
        menuVariantUpdateRequest.labels = updateDetailByCountryCode(labels, label, languageCode);
    }

    const oldDescription = getValueByCountryCode(descriptions, languageCode);
    if (description && description !== oldDescription) {
        menuVariantUpdateRequest.descriptions = updateDetailByCountryCode(descriptions, description, languageCode);
    }

    const updatedMenuVariant = await menuManagerApi.updateMenuVariant(
        menuUuid,
        menuVariantUuid,
        menuVariantUpdateRequest
    );
    commit(SET_MENU_VARIANT, updatedMenuVariant);

    return updatedMenuVariant;
};

const removeMenuElementFromCategory = async ({ dispatch, getters }, { categoryUuid, menuElementUuid }) => {
    const category = getters.getCategory(categoryUuid);
    const filteredMenuElements = setElementsPositions(
        category.menu_elements.filter(({ menu_element_uuid }) => menu_element_uuid !== menuElementUuid)
    );
    await dispatch('updateCategory', { categoryUuid, menuElements: filteredMenuElements });
};

const fetchModifierDetails = async ({ commit }, { modifierUuid, ...requestPayload }) => {
    const modfierDetails = await menuManagerApi.fetchModifierDetails(modifierUuid, requestPayload);
    commit(SET_MODIFIER_DETAILS, modfierDetails);
};

const fetchModifierGroupDetails = async ({ commit }, { modifierGroupUuid, ...requestPayload }) => {
    const modfierGroupDetails = await menuManagerApi.fetchModifierGroupDetails(modifierGroupUuid, requestPayload);
    commit(SET_MODIFIER_GROUP_DETAILS, modfierGroupDetails);
};

const fetchModifierGroups = async ({ commit }, payload) => {
    const modifierGroups = await menuManagerApi.fetchModifierGroups(payload);
    commit(SET_MODIFIER_GROUPS, modifierGroups);
    return modifierGroups;
};

const fetchModifierGroupsDetails = async ({ commit }, payload) => {
    const modfierGroupsDetails = await menuManagerApi.fetchModifierGroupsDetails(payload);
    commit(SET_MODIFIER_GROUP_DETAILS, modfierGroupsDetails);
    return modfierGroupsDetails;
};

const fetchModifierGroupsVariants = async ({ commit }, payload) => {
    const modfierGroupsVariants = await menuManagerApi.fetchModifierGroupsVariants(payload);
    commit(SET_MODIFIER_GROUPS_VARIANTS, modfierGroupsVariants);

    return modfierGroupsVariants;
};

const fetchModifierVariants = async ({ commit }, { modifierUuid, variantUuid }) => {
    const modifierVariants = await menuManagerApi.fetchModifierVariants(modifierUuid, { variantUuid });
    commit(SET_MODIFIERS_VARIANTS, modifierVariants);

    return modifierVariants;
};

const fetchModifiersVariants = async ({ commit }, variantUuid) => {
    const modifiersVariants = await menuManagerApi.fetchModifiersVariants({ variantUuid });
    commit(SET_MODIFIERS_VARIANTS, modifiersVariants);

    return modifiersVariants;
};
const fetchModifiers = async ({ commit }, payload) => {
    const modifiers = await menuManagerApi.fetchModifiers(payload);
    commit(SET_MODIFIERS, modifiers);

    return modifiers;
};

const fetchRestaurantMenus = async ({ commit }, payload) => {
    const restaurantMenus = await menuManagerApi.fetchRestaurantMenus(payload);
    commit(SET_RESTAURANT_MENUS, restaurantMenus);
    return restaurantMenus;
};

const fetchRestaurantStates = async ({ commit }, payload) => {
    const restaurantStates = await platformStateApi.fetchRestaurantStates(payload);
    commit(SET_RESTAURANT_STATES, restaurantStates);
    return restaurantStates;
};

const fetchVariants = async ({ commit }, payload) => {
    const variants = await menuManagerApi.fetchVariants(payload);
    commit(SET_VARIANTS, variants);

    return variants;
};

const importMenuItems = async ({ commit }, { menuUuid, menuItems }) => {
    const importedMenuItems = await menuManagerApi.importMenuItems(menuUuid, menuItems);

    importedMenuItems.forEach(
        ({ menu_item: menuItem, menu_item_variant: menuItemVariant, menu_element: menuElement }) => {
            commit(SET_MENU_ITEM, menuItem);
            commit(SET_MENU_ITEM_VARIANT, menuItemVariant);
            commit(SET_MENU_ELEMENT, menuElement);
            commit(ADD_MENU_ELEMENT_TO_CATEGORY, {
                categoryUuid: menuElement.category_uuid,
                menuElementUuid: menuElement.uuid,
            });
        }
    );
};
export default {
    createCategory,
    createMenuElement,
    createMenuItem,
    createMenuItemVariant,
    createModifier,
    createModifierGroup,
    createVariant,
    deleteCategory,
    deleteMenuItemVariant,
    deleteModifier,
    deleteModifierGroup,
    fetchCategories,
    fetchCategoriesDetails,
    fetchCategoriesVariants,
    fetchCategoryDetails,
    fetchEntireMenu,
    fetchMenu,
    fetchMenuDetails,
    fetchMenuElementCustomerDisplay,
    fetchMenuElements,
    fetchMenuItem,
    fetchMenuItemCategories,
    fetchMenuItemClassifications,
    fetchMenuItemDetails,
    fetchMenuItemNutritionalClassifications,
    fetchMenuItems,
    fetchMenuItemsDetails,
    fetchMenuItemsVariants,
    fetchMenuItemVariants,
    fetchMenuVariants,
    fetchModifierDetails,
    fetchModifierGroupDetails,
    fetchModifierGroups,
    fetchModifierGroupsDetails,
    fetchModifierGroupsVariants,
    fetchModifiers,
    fetchModifiersVariants,
    fetchModifierVariants,
    fetchRestaurantMenus,
    fetchRestaurantStates,
    fetchVariants,
    importMenuItems,
    removeMenuElementFromCategory,
    saveMenu,
    setMenuRequestHelpers,
    swapCategoryPosition,
    updateCategory,
    updateMenu,
    updateMenuElement,
    updateMenuElementsPosition,
    updateMenuItem,
    updateMenuVariant,
    updateModifier,
    updateModifierGroup,
    updateVariant,
    uploadMenuItemPicture,
    uploadMenuPicture,
};
