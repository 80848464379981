import {
    CLEAR_OPENING_HOURS_FETCH_REQUEST,
    CLEAR_SPECIAL_HOURS_FETCH_REQUEST,
    SET_KITCHENS_DATA_BY_KITCHEN_UUID,
    SET_OPENING_HOURS,
    SET_SPECIAL_HOURS,
    SET_OPENING_HOURS_CONCEPT_STATUS,
    SET_SPECIAL_HOURS_RESTAURANT_STATUS,
    SET_OPENING_HOURS_RESTAURANT_STATUS,
    SET_SPECIAL_HOURS_CONCEPT_STATUS,
    SET_KITCHEN_LOADING_STATUS,
    SET_OPENING_HOURS_FETCH_REQUEST,
    SET_SPECIAL_HOURS_FETCH_REQUEST,
} from '@/store/modules/openingHours/mutationsTypes';

export default {
    [SET_KITCHENS_DATA_BY_KITCHEN_UUID]: (state, kitchensDataByKitchenUuid) => {
        state.kitchensDataByKitchenUuid = kitchensDataByKitchenUuid;

        state.kitchensLoadingMap = Object.keys(kitchensDataByKitchenUuid).reduce((map, kitchenUuid) => {
            map[kitchenUuid] = { openingHours: false, specialHours: false };
            return map;
        }, {});
    },
    [SET_OPENING_HOURS]: (state, { kitchenUuid, conceptUuid, newOpeningHours }) => {
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].openingHours = newOpeningHours;
    },
    [SET_SPECIAL_HOURS]: (state, { kitchenUuid, conceptUuid, newSpecialHours }) => {
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].specialHours = newSpecialHours;
    },
    [SET_KITCHEN_LOADING_STATUS]: (state, { kitchenUuid, container, isLoading }) => {
        state.kitchensLoadingMap[kitchenUuid][container] = isLoading;
    },
    [SET_OPENING_HOURS_CONCEPT_STATUS]: (state, { kitchenUuid, conceptUuid, newStatus }) => {
        Object.values(state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants).forEach(
            (restaurant) => (restaurant.openingHoursStatus = newStatus)
        );
    },
    [SET_OPENING_HOURS_RESTAURANT_STATUS]: (state, { kitchenUuid, conceptUuid, restaurantUuid, newStatus }) => {
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants[
            restaurantUuid
        ].openingHoursStatus = newStatus;
    },
    [SET_SPECIAL_HOURS_RESTAURANT_STATUS]: (state, { kitchenUuid, conceptUuid, restaurantUuid, newStatus }) => {
        state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants[
            restaurantUuid
        ].specialHoursStatus = newStatus;
    },
    [SET_SPECIAL_HOURS_CONCEPT_STATUS]: (state, { kitchenUuid, conceptUuid, newStatus }) => {
        Object.values(state.kitchensDataByKitchenUuid[kitchenUuid].conceptsWithStatus[conceptUuid].restaurants).forEach(
            (restaurant) => (restaurant.specialHoursStatus = newStatus)
        );
    },
    [SET_OPENING_HOURS_FETCH_REQUEST]: (state, { kitchenUuid, conceptUuid, intervalId }) => {
        const restaurantIntervalKey = `${kitchenUuid}-${conceptUuid}`;

        state.openingHoursFetchRequests = {
            ...state.openingHoursFetchRequests,
            [restaurantIntervalKey]: intervalId,
        };
    },
    [SET_SPECIAL_HOURS_FETCH_REQUEST]: (state, { kitchenUuid, conceptUuid, intervalId }) => {
        const restaurantIntervalKey = `${kitchenUuid}-${conceptUuid}`;

        state.specialHoursFetchRequests = {
            ...state.specialHoursFetchRequests,
            [restaurantIntervalKey]: intervalId,
        };
    },
    [CLEAR_OPENING_HOURS_FETCH_REQUEST]: (state, { kitchenUuid, conceptUuid }) => {
        const restaurantIntervalKey = `${kitchenUuid}-${conceptUuid}`;

        clearInterval(state.openingHoursFetchRequests[restaurantIntervalKey]);

        delete state.openingHoursFetchRequests[restaurantIntervalKey];
    },
    [CLEAR_SPECIAL_HOURS_FETCH_REQUEST]: (state, { kitchenUuid, conceptUuid }) => {
        const restaurantIntervalKey = `${kitchenUuid}-${conceptUuid}`;

        clearInterval(state.specialHoursFetchRequests[restaurantIntervalKey]);

        delete state.specialHoursFetchRequests[restaurantIntervalKey];
    },
};
