import dayjs from 'dayjs';

export const getComparisonAggregation = (startDate, endDate) => {
    let aggregation = 'DAILY';
    const start = dayjs.utc(startDate);
    const end = dayjs.utc(endDate);
    const startOfMonth = start.startOf('month');
    const endOfMonth = end.endOf('month');
    const startWeekday = start.isoWeekday();
    const endWeekday = end.isoWeekday();

    if (start.isSame(startOfMonth, 'day') && end.isSame(endOfMonth, 'day')) {
        aggregation = 'MONTHLY';
    } else if (startWeekday === 1 && endWeekday === 7) {
        aggregation = 'WEEKLY';
    }

    return aggregation;
};

export const formatTimeShort = (date) => dayjs(date).format('lll');

export const dateDefaultFormat = (date) => dayjs(date).format('YYYY-MM-DD');

export const dateReadableFormat = (date) => dayjs(date).format('ll');

export const dateMonthYearFormat = (date) => dayjs(date).format('ddd MMM YY');

export const dateTimeShortFormat = (date) => dayjs(date).format('ll HH:mm');

export const dateWeekFormat = (date) => {
    const ISOWeekYear = dayjs(date).format('GGGG');
    const ISOWeekOfYear = dayjs(date).format('W');
    const currentDate = dayjs(date).format('MMM DD');

    return `${ISOWeekYear} W${ISOWeekOfYear} (${currentDate})`;
};
export const dateDayMonthFormat = (date) => dayjs(date).format('ddd MMM DD');

export const formatDateByAggregation = (date, aggregation = 'DEFAULT') => {
    const dateFormattingMap = {
        MONTHLY: dateMonthYearFormat,
        WEEKLY: dateWeekFormat,
        DAILY: dateReadableFormat,
        DEFAULT: dateDefaultFormat,
    };

    return dateFormattingMap[aggregation](date);
};

export const convertToTime = (time) => {
    const minutes = Math.floor(time);
    let seconds = Math.floor((time - Math.floor(time)) * 60);
    if (seconds < 10) seconds = `0${seconds}`;
    return `${minutes}m ${seconds}s`;
};

export const getBaseHoursByStep = (stepArray) => {
    let availableHours = [];

    for (let hour = 0; hour < 24; hour++) {
        for (const step of stepArray) {
            availableHours.push(dayjs().hour(hour).set('minute', step).format('HH:mm'));
        }
    }
    return availableHours;
};

export const getWeekdayByKey = (dayKey) => {
    const dayMapping = {
        0: 'monday',
        1: 'tuesday',
        2: 'wednesday',
        3: 'thursday',
        4: 'friday',
        5: 'saturday',
        6: 'sunday',
    };
    return dayMapping[dayKey];
};

export const removeSecondFromTime = (time) => {
    const timeFormat = 'HH:mm';
    return dayjs(time, timeFormat).format(timeFormat);
};

export const formatPossibleMidnightTime = (possibleMidnightString) => {
    if (possibleMidnightString === '00:00') {
        return '00:00 (+1)';
    } else if (possibleMidnightString === '00:00 (+1)') {
        return '00:00';
    }
    return possibleMidnightString;
};

export const getYesterday = () => dayjs().subtract(1, 'day').format();

export const getDateRange = (unit, subtractNumber = 0) => {
    const dateStamp = dayjs().subtract(subtractNumber, unit);
    const start = dateStamp.startOf(unit);
    const end = dateStamp.endOf(unit);

    return [start.format(), end.format()];
};

export const getWeekRange = (weeksToSubtract = 0) => {
    const week = dayjs().subtract(weeksToSubtract, 'week');
    const firstDayOfWeek = week.startOf('isoWeek');
    const lastDayOfWeek = week.endOf('isoWeek');

    return [firstDayOfWeek.format(), lastDayOfWeek.format()];
};

export const getMonthRange = (monthsToSubtract = 0) => {
    return getDateRange('month', monthsToSubtract);
};

export const getYearRange = (yearsToSubtract = 0) => {
    return getDateRange('year', yearsToSubtract);
};

export const getQuarterRange = (quartersToSubtract = 0) => {
    return getDateRange('quarter', quartersToSubtract);
};

export const isShiftShorterThanOneHour = (startTime, endTime) => {
    const start = dayjs(`2000-01-01 ${startTime}`);

    const endDay = formatPossibleMidnightTime(endTime) == '00:00' ? '2000-01-02' : '2000-01-01';

    const end = dayjs(`${endDay} ${endTime}`);

    return end.diff(start, 'minutes') < 60;
};

export const isDateInLessThanOneWeek = (date) => {
    return dayjs(date).diff(dayjs(), 'day') <= 7;
};
