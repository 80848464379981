import snakeize from 'snakeize';
import { missingItemsApi } from './clients';

export default {
    fetchMissingItemsOrders(
        startDate,
        endDate,
        {
            page = 1,
            perPage = 10,
            desc = true,
            orderBy = 'order_timestamp',
            locationUuids,
            conceptUuids,
            countryCode,
            platformUuids,
        }
    ) {
        return missingItemsApi.searchMissingItemsOrders(
            snakeize({
                startDate,
                endDate,
                page,
                perPage,
                locationUuids,
                conceptUuids,
                platformUuids,
                countryCode,
                orderBy,
                desc,
            })
        );
    },
};
