import { configure, extend, setInteractionMode } from 'vee-validate';
import i18n from '@/i18n';
import { required, image, max, integer, numeric, double, min_value, min } from 'vee-validate/dist/rules';

setInteractionMode('eager');

configure({
    defaultMessage: (field, values) => {
        values._field_ = i18n.t(field);
        return i18n.t(`validations.messages.${values._rule_}`, values);
    },
});

extend('image', image);
extend('required', required);
extend('max', max);
extend('min', min);
extend('integer', integer);
extend('numeric', numeric);
extend('double', double);
extend('min_value', min_value);
