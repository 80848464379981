import Vue from 'vue';
import { parseDate } from '@/apis';
import { HygieneReportsApi } from '@tasterkitchens/hygiene-reports-client';
const clientV2 = require('@tasterkitchens/data-one-board-client-v2');
const clientV1 = require('@tasterkitchens/data-one-board-client-v1');

clientV1.ApiClient.parseDate = parseDate;
clientV2.ApiClient.parseDate = parseDate;

const oneBoardUrlV1 = process.env.VUE_APP_ONE_BOARD_ENDPOINT;
const oneBoardUrlV2 = process.env.VUE_APP_DATA_ONE_BOARD_ENDPOINT_V2;
const oneBoardHygieneUrl = process.env.VUE_APP_HYGIENE_REPORTS_API_URL;

/**
 * Get a configured api client.
 * @return {client:ApiClient} Api client.
 */
const configureApiClient = (apiClient, apiUrl, tokenCallback) => {
    apiClient.basePath = apiUrl;
    apiClient.defaultHeaders = {};
    const OAuth2 = apiClient.authentications['OAuth2'];
    OAuth2.type = 'bearer';
    OAuth2.accessToken = tokenCallback;
};

const getToken = () => Vue.prototype.$auth.getToken();

const apiClientV1 = new clientV1.ApiClient();
const apiClientV2 = new clientV2.ApiClient();

configureApiClient(apiClientV1, oneBoardUrlV1, getToken);
configureApiClient(apiClientV2, oneBoardUrlV2, getToken);

const locationsApi = new clientV1.LocationsApi(apiClientV1);
const reviewsApi = new clientV2.ReviewsApi(apiClientV2);
const performanceApi = new clientV2.MetricsApi(apiClientV2);
const financeApi = new clientV2.MetricsApi(apiClientV2);
const qualityApi = new clientV2.MetricsApi(apiClientV2);
const missingItemsApi = new clientV2.MissingItemsOrdersApi(apiClientV2);
const metricsMissingItemsApi = new clientV2.MetricsMissingItemsOrdersApi(apiClientV2);
const hygieneApi = new HygieneReportsApi({
    accessToken: getToken,
    isJsonMime: () => true,
    basePath: oneBoardHygieneUrl,
});

export {
    locationsApi,
    reviewsApi,
    performanceApi,
    financeApi,
    qualityApi,
    hygieneApi,
    missingItemsApi,
    metricsMissingItemsApi,
};
