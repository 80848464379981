import Vue from 'vue';
import { parseDate } from '@/apis';
import { Configuration, KitchenManagerApi } from '@tasterkitchens/client-ts-gateway-platforms-v2';

const gatewayPlatformsV2Client = require('@tasterkitchens/gateway-platforms-v2-client');
const gatewayPlatformsV2Url = process.env.VUE_APP_GATEWAY_PLATFORMS_V2_API_URL;

const gatewayPlatformsClient = require('@tasterkitchens/gateway-platforms-client');
const gatewayPlatformsUrl = process.env.VUE_APP_GATEWAY_PLATFORMS_API_URL;

gatewayPlatformsClient.ApiClient.parseDate = parseDate;
gatewayPlatformsV2Client.ApiClient.parseDate = parseDate;

const configuredClientV2 = (() => {
    const apiClient = gatewayPlatformsV2Client.ApiClient.instance;
    apiClient.basePath = gatewayPlatformsV2Url;
    apiClient.defaultHeaders = {};
    const OAuth2 = apiClient.authentications['OAuth2'];
    OAuth2.type = 'bearer';
    OAuth2.accessToken = () => Vue.prototype.$auth.getToken();
    return apiClient;
})();

const configuredClient = (() => {
    const apiClient = gatewayPlatformsClient.ApiClient.instance;
    apiClient.basePath = gatewayPlatformsUrl;
    apiClient.defaultHeaders = {};
    const OAuth2 = apiClient.authentications['OAuth2'];
    OAuth2.type = 'bearer';
    OAuth2.accessToken = () => Vue.prototype.$auth.getToken();
    return apiClient;
})();

const configurationTsClientV2 = (() => {
    return new Configuration({
        accessToken: () => `Bearer ${Vue.prototype.$auth.getToken()}`,
        basePath: process.env.VUE_APP_GATEWAY_PLATFORMS_V2_API_URL,
    });
})();

export const kitchenManagerClient = new gatewayPlatformsClient.KitchenManagerApi(configuredClient);
export const kitchenManagerV2 = new KitchenManagerApi(configurationTsClientV2);

export const menuManagerClient = new gatewayPlatformsClient.MenuManagerApi(configuredClient);
export const menuManagerV2Client = new gatewayPlatformsV2Client.MenuManagerApi(configuredClientV2);
export const onBoardingClient = new gatewayPlatformsClient.OnBoardingApi(configuredClient);
export const platformStateClient = new gatewayPlatformsClient.PlatformStateApi(configuredClient);
export const platformLiveClient = new gatewayPlatformsClient.PlatformLiveApi(configuredClient);
