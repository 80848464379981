import Vue from 'vue';
import { Configuration, DeliveriesApi, ProvidersApi } from '@tasterkitchens/client-ts-gateway-supply-v1';

const configuration = (() => {
    return new Configuration({
        accessToken: () => `Bearer ${Vue.prototype.$auth.getToken()}`,
        basePath: process.env.VUE_APP_GATEWAY_SUPPLY_API_URL,
    });
})();

export const providersApi = new ProvidersApi(configuration);
export const deliveriesApi = new DeliveriesApi(configuration);
