import { qualityApi } from '@/apis/oneBoard/clients';

export default {
    fetchQualitySummary({ aggregation, conceptUuids, endDate, locationUuids, platformUuids, startDate }) {
        return qualityApi.fetchQualitySummary({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            start_date: startDate,
        });
    },
    fetchQualitySplitChart({ aggregation, conceptUuids, endDate, locationUuids, platformUuids, splitBy, startDate }) {
        return qualityApi.fetchQualitySplitChart({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            split_by: splitBy,
            start_date: startDate,
        });
    },
    fetchQualityTimeseries({ aggregation, conceptUuids, endDate, locationUuids, platformUuids, splitBy, startDate }) {
        return qualityApi.fetchQualityTimeseries({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            split_by: splitBy,
            start_date: startDate,
        });
    },
};
