import snakeize from 'snakeize';

export default {
    install: function (Vue) {
        Vue.prototype.$dataLayerPush = (eventData) => {
            if (!window.dataLayer) {
                console.warn('gtm dataLayer not found');
            } else {
                window.dataLayer.push(snakeize(eventData));
            }
        };
    },
};
