import { chain, groupBy, keyBy, sortBy } from 'lodash';

const getCategoriesByUuids =
    (state) =>
    (categoryUuids = []) =>
        categoryUuids.map((uuid) => state.categories[uuid]).filter(Boolean);

const getCategory = (state) => (categoryUuid) => {
    return state.categories[categoryUuid];
};

const getCategoryDetails = (state) => (categoryUuid, locationUuid) => {
    const categoryDetailsGroupByCategoryUuid = groupBy(state.categoryDetails, 'category_uuid');

    return categoryDetailsGroupByCategoryUuid[categoryUuid]
        ? categoryDetailsGroupByCategoryUuid[categoryUuid].filter(({ location_uuid }) => location_uuid === locationUuid)
        : [];
};

const getCategoryVariantByVariantUuid = (state) => (categoryUuid, variantUuid) => {
    const categoriesVariants = chain(state.categoriesVariants)
        .groupBy('category_uuid')
        .mapValues((categoryVariants) => keyBy(categoryVariants, 'variant_uuid'))
        .value();

    return categoriesVariants[categoryUuid][variantUuid];
};

const getClassificationMap = (state) => state.menuItemClassifications;

const getNutritionalClassifications = (state) => state.nutritionalClassifications;

const getMenuElementCustomerDisplay = (state) => state.menuElementCustomerDisplay;

const getMenu = (state) => (menuUuid) => state.menus[menuUuid];

const getMenuDetails = (state) => (menuUuid, locationUuid) => {
    const menuDetailsGroupByMenuUuid = groupBy(state.menuDetails, 'menu_uuid');
    return menuDetailsGroupByMenuUuid[menuUuid]
        ? menuDetailsGroupByMenuUuid[menuUuid].filter(({ location_uuid }) => location_uuid === locationUuid)
        : [];
};

const getMenuElement = (state) => (menuElementUuid) => {
    return state.menuElements[menuElementUuid];
};

const getMenuElementsFromCategory = (_state, getters) => (categoryUuid) => {
    const { menu_elements } = getters.getCategory(categoryUuid);

    return menu_elements.map(({ menu_element_uuid }) => getters.getMenuElement(menu_element_uuid)).filter(Boolean);
};

const getMenuItemByUuid = (state) => (menuItemUuid) => state.menuItems[menuItemUuid];

const getMenuItemCategoriesFilteredByMenuItemType = (state, getters) => (menuItemType) => {
    const menuItemCategories = getters.getMenuItemCategoryList;
    return menuItemCategories.filter(({ menu_item_type }) => menu_item_type === menuItemType);
};

const getMenuItemSortedCategories = (state, getters) => () => {
    const menuItemCategoryItems = getters
        .getMenuItemCategoriesFilteredByMenuItemType('MENU_ITEM')
        .map(({ label, uuid }) => ({
            label,
            uuid,
        }));

    const sortOrder = ['main', 'side', 'dessert', 'drink', 'starter', 'sauce', 'modifier', 'marketing'];

    return sortBy(menuItemCategoryItems, ({ label }) =>
        sortOrder.indexOf(label) !== -1 ? sortOrder.indexOf(label) : sortOrder.length
    );
};

const getMenuItemCategoryByMenuItemUuid = (state, getters) => (menuItemUuid) => {
    const menuItem = getters.getMenuItemByUuid(menuItemUuid);
    if (!menuItem) return;

    return getters.getMenuItemCategories[menuItem.category_uuid];
};

const getMenuItemCategoryLabelByUuid = (state) => (categoryUuid) => {
    const category = state.menuItemCategories[categoryUuid];

    if (!category) return;

    return category.label;
};

const getMenuItemCategories = (state) => state.menuItemCategories;

const getMenuItemCategoryList = (state) => Object.values(state.menuItemCategories);

const getMenuItemDetails = (state) => (menuItemUuid, locationUuid) => {
    const menuItemDetailsGroupByMenuItemUuid = groupBy(state.menuItemDetails, 'menu_item_uuid');

    return menuItemDetailsGroupByMenuItemUuid[menuItemUuid]
        ? menuItemDetailsGroupByMenuItemUuid[menuItemUuid].filter(({ location_uuid }) => location_uuid === locationUuid)
        : [];
};

const getMenuItemVariantByVariantUuid = (state) => (menuItemUuid, variantUuid) => {
    const menuItemsVariants = chain(state.menuItemsVariants)
        .groupBy('menu_item_uuid')
        .mapValues((menuItemVariants) => keyBy(menuItemVariants, 'variant_uuid'))
        .value();

    return menuItemsVariants[menuItemUuid][variantUuid];
};

const getMenuItemVariants = (state) => (menuItemUuid) => {
    const menuItemVariantsByMenuItemUuid = groupBy(state.menuItemsVariants, 'menu_item_uuid');

    if (!menuItemVariantsByMenuItemUuid[menuItemUuid]) return [];

    return menuItemVariantsByMenuItemUuid[menuItemUuid].filter(({ is_deleted }) => !is_deleted);
};

const getMenuItems = (state) => Object.values(state.menuItems);

const getMenuItemsByConceptUuid = (_state, getters) => (conceptUuid) =>
    getters.getMenuItems.filter(({ concept_uuids }) => concept_uuids.includes(conceptUuid));

const getMenuItemsFromCategory = (_state, getters) => (categoryUuid) => {
    const menuElements = getters.getMenuElementsFromCategory(categoryUuid);

    return menuElements.map(({ menu_item_uuid }) => getters.getMenuItemByUuid(menu_item_uuid)).filter(Boolean);
};

const getMenuItemsVariantsByVariantUuid = (state) => (variantUuid) => {
    const menuItemsVariants = groupBy(state.menuItemsVariants, 'variant_uuid');

    return menuItemsVariants[variantUuid];
};

const getMenuRequestHelpers = (state) => state.menuRequestHelpers;

const getMenuVariantByUuid = (state) => (menuVariantUuid) => state.menusVariants[menuVariantUuid];

const getMenusVariantsMapByVariantUuid = (state) => keyBy(state.menusVariants, 'variant_uuid');

const getModifierByUuid = (state) => (modifierUuid) => state.modifiers[modifierUuid];

const getModifierDetails = (state) => (modifierUuid, locationUuid) => {
    const modifierDetailsGroupByModifierUuid = groupBy(state.modifierDetails, 'modifier_uuid');

    return modifierDetailsGroupByModifierUuid[modifierUuid]
        ? modifierDetailsGroupByModifierUuid[modifierUuid].filter(({ location_uuid }) => location_uuid === locationUuid)
        : [];
};

const getModifierGroupByUuid = (state) => (modifierGroupUuid) => state.modifierGroups[modifierGroupUuid];

const getModifierGroups = (state) => Object.values(state.modifierGroups);

const getModifierGroupDetails = (state) => (modifierGroupUuid, locationUuid) => {
    const modifierGroupDetailsGroupByModifierGroupUuid = groupBy(state.modifierGroupDetails, 'modifier_group_uuid');

    return modifierGroupDetailsGroupByModifierGroupUuid[modifierGroupUuid]
        ? modifierGroupDetailsGroupByModifierGroupUuid[modifierGroupUuid].filter(
              ({ location_uuid }) => location_uuid === locationUuid
          )
        : [];
};

const getModifierGroupVariantByVariantUuid = (state) => (modifierGroupUuid, variantUuid) => {
    const modifierGroupsVariants = chain(state.modifierGroupsVariants)
        .groupBy('modifier_group_uuid')
        .mapValues((modifierGroupVariants) => keyBy(modifierGroupVariants, 'variant_uuid'))
        .value();

    return modifierGroupsVariants[modifierGroupUuid][variantUuid];
};

const getModifiers = (state) => Object.values(state.modifiers);

export const getCategoriesFromMenu = (state, getters) => (menuUuid) => {
    const { categories: menuCategories } = getters.getMenu(menuUuid);
    const categoriesMap = state.categories;

    return menuCategories.map(({ category_uuid }) => categoriesMap[category_uuid]).filter(Boolean);
};

export const getMenuElementsFromMenu = (state, getters) => (menuUuid) => {
    const categories = getters.getCategoriesFromMenu(menuUuid);
    const menuElementsMap = state.menuElements;

    return categories
        .map(({ menu_elements }) =>
            menu_elements.map(({ menu_element_uuid }) => menuElementsMap[menu_element_uuid]).filter(Boolean)
        )
        .flat();
};

export const getModifierGroupsFromMenu = (_state, getters) => (menuUuid) => {
    const modifierGroups = getters.getModifierGroups;

    return modifierGroups.filter(({ menu_uuid }) => menu_uuid === menuUuid);
};

const getModifierVariantByVariantUuid = (state) => (modifierUuid, variantUuid) => {
    const modifiersVariants = chain(state.modifiersVariants)
        .groupBy('modifier_uuid')
        .mapValues((modifierVariants) => keyBy(modifierVariants, 'variant_uuid'))
        .value();

    return modifiersVariants[modifierUuid][variantUuid];
};

const getRestaurantMenus = (state) => Object.values(state.restaurantMenus);

const getRestaurantMenusByMenuUuid = (state) => (menuUuid) => {
    const restaurantMenusGroupByMenuUuid = groupBy(state.restaurantMenus, 'menu_uuid');
    return restaurantMenusGroupByMenuUuid[menuUuid];
};

const getRestaurantStates = (state) => Object.values(state.restaurantStates);

const getVariantsByMenuUuid = (state) => (menuUuid) => groupBy(state.variants, 'menu_uuid')[menuUuid];

export default {
    getCategoriesByUuids,
    getCategoriesFromMenu,
    getCategory,
    getCategoryDetails,
    getCategoryVariantByVariantUuid,
    getClassificationMap,
    getMenu,
    getMenuDetails,
    getMenuElement,
    getMenuElementCustomerDisplay,
    getMenuElementsFromCategory,
    getMenuElementsFromMenu,
    getMenuItemByUuid,
    getMenuItemCategories,
    getMenuItemCategoriesFilteredByMenuItemType,
    getMenuItemSortedCategories,
    getMenuItemCategoryLabelByUuid,
    getMenuItemCategoryByMenuItemUuid,
    getMenuItemCategoryList,
    getMenuItemDetails,
    getMenuItemVariantByVariantUuid,
    getMenuItemVariants,
    getMenuItems,
    getMenuItemsByConceptUuid,
    getMenuItemsFromCategory,
    getMenuItemsVariantsByVariantUuid,
    getMenuRequestHelpers,
    getMenuVariantByUuid,
    getMenusVariantsMapByVariantUuid,
    getModifierByUuid,
    getModifierDetails,
    getModifierGroupByUuid,
    getModifierGroupDetails,
    getModifierGroupVariantByVariantUuid,
    getModifierGroups,
    getModifierGroupsFromMenu,
    getModifierVariantByVariantUuid,
    getModifiers,
    getNutritionalClassifications,
    getRestaurantMenus,
    getRestaurantMenusByMenuUuid,
    getRestaurantStates,
    getVariantsByMenuUuid,
};
