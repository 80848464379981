import performance from '@/apis/oneBoard/metrics/performance';
import finance from '@/apis/oneBoard/metrics/finance';
import quality from '@/apis/oneBoard/metrics/quality';
import missingItems from '@/apis/oneBoard/metrics/missingItems';

export default {
    ...finance,
    ...performance,
    ...quality,
    ...missingItems,
};
