import mutations from '@/store/modules/openingHours/mutations';
import actions from '@/store/modules/openingHours/actions';
import getters from '@/store/modules/openingHours/getters';

export default {
    namespaced: true,
    state: {
        kitchensDataByKitchenUuid: {},
        kitchensLoadingMap: {},
        openingHoursFetchRequests: {},
        specialHoursFetchRequests: {},
    },
    actions,
    getters,
    mutations,
};
