import Vue from 'vue';

const getError = (state) => (stateKey) => {
    if (typeof state[stateKey] === 'undefined') {
        Vue.set(state, stateKey, { error: null });
    }
    return state[stateKey].error;
};

const getLoadingStatus = (state) => (stateKey) => {
    if (typeof state[stateKey] === 'undefined') {
        Vue.set(state, stateKey, { isLoading: false });
    }
    return state[stateKey].isLoading;
};

export default {
    getError,
    getLoadingStatus,
};
