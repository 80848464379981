import {
    SET_CONCEPTS,
    SET_KITCHEN,
    SET_KITCHENS,
    SET_LIVE_STATUS_BY_LOCATION_UUID,
    SET_LIVE_STATUSES,
    SET_LOCATION,
    SET_LOCATIONS,
    SET_MISSING_ITEMS_ORDERS,
    SET_MISSING_ITEMS_TIMESERIES,
    SET_PLATFORMS,
    SET_RESTAURANTS,
    SET_REVIEWS,
} from '@/store/modules/oneBoard/mutationTypes';
import oneBoardApi from '@/apis/oneBoard';

import { kitchenManagerApi } from '@/apis/kitchenManagerApi';
import { keyBy } from 'lodash';

const setLocation = ({ commit }, location) => {
    commit(SET_LOCATION, location);
};

const setKitchen = ({ commit }, kitchen) => {
    commit(SET_KITCHEN, kitchen);
};

const fetchLocations = async ({ commit, dispatch }) => {
    dispatch('status/setLoadingStatus', { stateKey: 'locations', isLoading: true }, { root: true });
    try {
        const locations = await kitchenManagerApi.fetchLocations();
        commit(SET_LOCATIONS, locations);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'locations', isLoading: true }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'locations', isLoading: false }, { root: true });
    }
};

const fetchKitchens = async ({ commit, dispatch }) => {
    dispatch('status/setLoadingStatus', { stateKey: 'kitchens', isLoading: true }, { root: true });
    try {
        const kitchens = await kitchenManagerApi.fetchKitchens();
        commit(SET_KITCHENS, kitchens);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'kitchens', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'kitchens', isLoading: false }, { root: true });
    }
};

const fetchConcepts = async ({ commit, dispatch }) => {
    dispatch('status/setLoadingStatus', { stateKey: 'concepts', isLoading: true }, { root: true });
    try {
        const concepts = await kitchenManagerApi.fetchConcepts();
        commit(SET_CONCEPTS, concepts);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'concepts', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'concepts', isLoading: false }, { root: true });
    }
};

const fetchPlatforms = async ({ commit, dispatch }) => {
    dispatch('status/setLoadingStatus', { stateKey: 'platforms', isLoading: true }, { root: true });
    try {
        const platforms = await kitchenManagerApi.fetchPlatforms();
        commit(SET_PLATFORMS, platforms);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'platforms', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'platforms', isLoading: false }, { root: true });
    }
};

const fetchRestaurants = async ({ commit, dispatch, getters }, { refetch = false, ...payload } = {}) => {
    const hasRestaurants = !!getters.getRestaurantList.length;

    if (!refetch && hasRestaurants) return;

    dispatch('status/setLoadingStatus', { stateKey: 'restaurants', isLoading: true }, { root: true });
    try {
        const restaurants = await kitchenManagerApi.fetchRestaurants(payload);
        commit(SET_RESTAURANTS, keyBy(restaurants, 'uuid'));
        return restaurants;
    } catch (error) {
        dispatch('status/setError', { stateKey: 'restaurants', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'restaurants', isLoading: false }, { root: true });
    }
};

const fetchLiveStatuses = async ({ commit, dispatch }, { backgroundFetch = false } = {}) => {
    !backgroundFetch &&
        dispatch('status/setLoadingStatus', { stateKey: 'liveStatuses', isLoading: true }, { root: true });
    try {
        const liveStatuses = await oneBoardApi.fetchLiveStatuses();
        commit(SET_LIVE_STATUSES, liveStatuses);
        return liveStatuses;
    } catch (error) {
        dispatch('status/setError', { stateKey: 'liveStatuses', error }, { root: true });
        throw error;
    } finally {
        !backgroundFetch &&
            dispatch('status/setLoadingStatus', { stateKey: 'liveStatuses', isLoading: false }, { root: true });
    }
};

const fetchLiveStatusByLocationUuid = async ({ commit, dispatch }, locationUuid) => {
    dispatch('status/setLoadingStatus', { stateKey: 'liveStatus', isLoading: true }, { root: true });
    try {
        const liveStatus = await oneBoardApi.fetchLiveStatusByLocationUuid(locationUuid);

        commit(SET_LIVE_STATUS_BY_LOCATION_UUID, liveStatus);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'liveStatus', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'liveStatus', isLoading: false }, { root: true });
    }
};

const fetchReviews = async ({ commit, dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'reviews', isLoading: true }, { root: true });
    try {
        const { startDate, endDate, ...otherReviewsParams } = payload;

        const reviews = await oneBoardApi.fetchReviews(startDate, endDate, otherReviewsParams);

        commit(SET_REVIEWS, reviews);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'reviews', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'reviews', isLoading: false }, { root: true });
    }
};

const fetchMissingItemsOrders = async ({ commit, dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'missingItems', isLoading: true }, { root: true });

    try {
        const { startDate, endDate, ...otherMissingItemsParams } = payload;

        const missingItemsOrders = await oneBoardApi.fetchMissingItemsOrders(
            startDate,
            endDate,
            otherMissingItemsParams
        );

        commit(SET_MISSING_ITEMS_ORDERS, missingItemsOrders);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'missingItems', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'missingItems', isLoading: false }, { root: true });
    }
};

const fetchMissingItemsTimeSeries = async ({ commit, dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'missingItemsChart', isLoading: true }, { root: true });

    try {
        const { startDate, endDate, ...otherMissingItemsParams } = payload;

        const missingItemsTimeSeries = await oneBoardApi.fetchMissingItemsTimeSeries(
            startDate,
            endDate,
            otherMissingItemsParams
        );

        commit(SET_MISSING_ITEMS_TIMESERIES, missingItemsTimeSeries);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'missingItemsChart', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'missingItemsChart', isLoading: false }, { root: true });
    }
};

export default {
    fetchConcepts,
    fetchKitchens,
    fetchLiveStatusByLocationUuid,
    fetchLiveStatuses,
    fetchLocations,
    fetchPlatforms,
    fetchRestaurants,
    fetchReviews,
    setKitchen,
    setLocation,
    fetchMissingItemsOrders,
    fetchMissingItemsTimeSeries,
};
