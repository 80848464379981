import {
    SET_CONCEPTS,
    SET_KITCHEN,
    SET_KITCHENS,
    SET_LIVE_STATUS_BY_LOCATION_UUID,
    SET_LIVE_STATUSES,
    SET_LOCATION,
    SET_LOCATIONS,
    SET_PLATFORMS,
    SET_RESTAURANTS,
    SET_REVIEWS,
    SET_MISSING_ITEMS_ORDERS,
    SET_MISSING_ITEMS_TIMESERIES,
} from '@/store/modules/oneBoard/mutationTypes';

export default {
    [SET_LOCATION]: (state, location) => {
        state.currentLocation = location;
    },
    [SET_KITCHEN]: (state, kitchen) => {
        state.currentKitchen = kitchen;
    },
    [SET_LOCATIONS]: (state, locations) => {
        state.locations = locations;
    },
    [SET_KITCHENS]: (state, kitchens) => {
        state.kitchens = kitchens;
    },
    [SET_CONCEPTS]: (state, concepts) => {
        state.concepts = concepts;
    },
    [SET_PLATFORMS]: (state, platforms) => {
        state.platforms = platforms;
    },
    [SET_LIVE_STATUSES]: (state, liveStatuses) => {
        state.liveStatuses = liveStatuses;
    },
    [SET_LIVE_STATUS_BY_LOCATION_UUID]: (state, liveStatus) => {
        state.liveStatus = liveStatus;
    },
    [SET_REVIEWS]: (state, reviews) => {
        state.reviews = reviews;
    },
    [SET_RESTAURANTS]: (state, restaurants) => {
        state.restaurants = restaurants;
    },
    [SET_MISSING_ITEMS_ORDERS]: (state, missingItemsOrders) => {
        state.missingItemsOrders = missingItemsOrders;
    },
    [SET_MISSING_ITEMS_TIMESERIES]: (state, missingItemsTimeSeries) => {
        state.missingItemsTimeSeries = missingItemsTimeSeries;
    },
};
