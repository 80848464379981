import { financeApi } from '@/apis/oneBoard/clients';

export default {
    fetchFinanceSummary({ aggregation, endDate, locationUuids, startDate }) {
        return financeApi.fetchFinanceSummary({
            aggregation,
            end_date: endDate,
            location_uuids: locationUuids,
            start_date: startDate,
        });
    },
    fetchFinanceTimeChart({ aggregation, endDate, locationUuids, startDate }) {
        return financeApi.fetchFinanceTimeChart({
            aggregation,
            end_date: endDate,
            location_uuids: locationUuids,
            start_date: startDate,
        });
    },
    fetchFinanceTimeseries({ aggregation, endDate, locationUuids, splitBy, startDate }) {
        return financeApi.fetchFinanceTimeseries({
            aggregation,
            end_date: endDate,
            location_uuids: locationUuids,
            split_by: splitBy,
            start_date: startDate,
        });
    },
};
