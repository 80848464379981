export const CLEAR_OPENING_HOURS_FETCH_REQUEST = 'CLEAR_OPENING_HOURS_FETCH_REQUEST';
export const CLEAR_SPECIAL_HOURS_FETCH_REQUEST = 'CLEAR_SPECIAL_HOURS_FETCH_REQUEST';
export const SET_KITCHENS_DATA_BY_KITCHEN_UUID = 'SET_KITCHENS_DATA_BY_KITCHEN_UUID';
export const SET_KITCHEN_LOADING_STATUS = 'SET_KITCHEN_LOADING_STATUS';
export const SET_OPENING_HOURS = 'SET_OPENING_HOURS';
export const SET_OPENING_HOURS_CONCEPT_STATUS = 'SET_OPENING_HOURS_CONCEPT_STATUS';
export const SET_OPENING_HOURS_FETCH_REQUEST = 'SET_OPENING_HOURS_FETCH_REQUEST';
export const SET_OPENING_HOURS_RESTAURANT_STATUS = 'SET_OPENING_HOURS_RESTAURANT_STATUS';
export const SET_SPECIAL_HOURS = 'SET_SPECIAL_HOURS';
export const SET_SPECIAL_HOURS_CONCEPT_STATUS = 'SET_SPECIAL_HOURS_CONCEPT_STATUS';
export const SET_SPECIAL_HOURS_FETCH_REQUEST = 'SET_SPECIAL_HOURS_FETCH_REQUEST';
export const SET_SPECIAL_HOURS_RESTAURANT_STATUS = 'SET_SPECIAL_HOURS_RESTAURANT_STATUS';
