export const SET_CONCEPTS = 'SET_CONCEPTS';
export const SET_KITCHEN = 'SET_KITCHEN';
export const SET_KITCHENS = 'SET_KITCHENS';
export const SET_LIVE_STATUSES = 'SET_LIVE_STATUSES';
export const SET_LIVE_STATUS_BY_LOCATION_UUID = 'SET_LIVE_STATUS_BY_LOCATION_UUID';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_MEPS = 'SET_MEPS';
export const SET_PLATFORMS = 'SET_PLATFORMS';
export const SET_RESTAURANTS = 'SET_RESTAURANTS';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_MISSING_ITEMS_ORDERS = 'SET_MISSING_ITEMS_ORDERS';
export const SET_MISSING_ITEMS_TIMESERIES = 'SET_MISSING_ITEMS_TIMESERIES';
