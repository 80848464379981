import {
    ADD_CATEGORY_TO_MENU,
    ADD_MENU_ELEMENT_TO_CATEGORY,
    DELETE_MODIFIER_GROUP,
    SET_NUTRITIONAL_CLASSIFICATIONS,
    SET_CATEGORIES,
    SET_CATEGORIES_VARIANTS,
    SET_CATEGORY,
    SET_CATEGORY_DETAILS,
    SET_CATEGORY_VARIANT,
    SET_MENU,
    SET_MENU_DETAILS,
    SET_MENU_ELEMENT,
    SET_MENU_ELEMENTS,
    SET_MENU_ITEM,
    SET_MENU_ITEM_CATEGORIES,
    SET_MENU_ITEM_CLASSIFICATIONS,
    SET_MENU_ITEM_DETAIL,
    SET_MENU_ITEM_DETAILS,
    SET_MENU_ITEM_VARIANT,
    SET_MENU_ITEMS,
    SET_MENU_ITEMS_VARIANTS,
    SET_MENU_REQUEST_HELPERS,
    SET_MENU_VARIANT,
    SET_MENUS_VARIANTS,
    SET_MODIFIER,
    SET_MODIFIER_DETAILS,
    SET_MODIFIER_GROUP,
    SET_MODIFIER_GROUP_DETAILS,
    SET_MODIFIER_GROUP_VARIANT,
    SET_MODIFIER_GROUPS,
    SET_MODIFIER_GROUPS_VARIANTS,
    SET_MODIFIERS,
    SET_MODIFIERS_VARIANTS,
    SET_RESTAURANT_MENUS,
    SET_RESTAURANT_STATES,
    SET_VARIANT,
    SET_VARIANTS,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_DETAIL,
    UPDATE_MENU_DETAIL,
    UPDATE_MENU_ITEM_DETAIL,
    UPDATE_MENU_ITEM_VARIANT,
    UPDATE_MODIFIER_DETAIL,
    UPDATE_MODIFIER_GROUP_DETAIL,
    SET_MENU_ELEMENT_CUSTOMER_DISPLAY,
} from '@/store/modules/menuManager/mutationTypes';
import { keyBy } from 'lodash';
import { setElementsPositions } from '@/utils/menuManagerHelpers';

export default {
    [ADD_CATEGORY_TO_MENU]: (state, { categoryUuid, menuUuid }) => {
        state.menus[menuUuid].categories = setElementsPositions([
            ...state.menus[menuUuid].categories,
            { category_uuid: categoryUuid },
        ]);
    },

    [ADD_MENU_ELEMENT_TO_CATEGORY]: (state, { categoryUuid, menuElementUuid }) => {
        const categoryMenuElements = setElementsPositions([
            ...state.categories[categoryUuid].menu_elements,
            { menu_element_uuid: menuElementUuid },
        ]);

        state.categories[categoryUuid] = {
            ...state.categories[categoryUuid],
            menu_elements: categoryMenuElements,
        };
    },

    [DELETE_MODIFIER_GROUP]: (state, modifierGroupUuid) => {
        const newModifiersGroups = Object.values(state.modifierGroups).filter(({ uuid }) => uuid !== modifierGroupUuid);

        state.modifierGroups = keyBy(newModifiersGroups, 'uuid');
    },

    [SET_NUTRITIONAL_CLASSIFICATIONS]: (state, nutritionalClassifications) => {
        state.nutritionalClassifications = nutritionalClassifications;
    },

    [SET_CATEGORIES]: (state, categories) => {
        const categoriesMap = keyBy(categories, 'uuid');
        state.categories = { ...state.categories, ...categoriesMap };
    },

    [SET_CATEGORIES_VARIANTS]: (state, categoriesVariants) => {
        const categoriesVariantsMap = keyBy(categoriesVariants, 'uuid');
        state.categoriesVariants = { ...state.categoriesVariants, ...categoriesVariantsMap };
    },

    [SET_MENU_DETAILS]: (state, menuDetails) => {
        const menuDetailsMap = keyBy(menuDetails, 'uuid');
        state.menuDetails = { ...state.menuDetails, ...menuDetailsMap };
    },

    [SET_MENU_ELEMENT]: (state, menuElement) => {
        state.menuElements = { ...state.menuElements, [menuElement.uuid]: menuElement };
    },

    [SET_MENU_ELEMENTS]: (state, menuElements) => {
        const menuElementsMap = keyBy(menuElements, 'uuid');
        state.menuElements = { ...state.menuElements, ...menuElementsMap };
    },

    [SET_MENU]: (state, menu) => {
        state.menus = { ...state.menus, [menu.uuid]: menu };
    },

    [SET_MENUS_VARIANTS]: (state, menusVariants) => {
        const menusVariantsMap = keyBy(menusVariants, 'uuid');
        state.menusVariants = { ...state.menusVariants, ...menusVariantsMap };
    },

    [SET_MENU_ELEMENT_CUSTOMER_DISPLAY]: (state, menuElementCustomerDisplay) => {
        state.menuElementCustomerDisplay = menuElementCustomerDisplay;
    },

    [SET_MENU_ITEM_CLASSIFICATIONS]: (state, menuItemClassifications) => {
        const menuItemClassificationsMap = keyBy(menuItemClassifications, 'uuid');
        state.menuItemClassifications = { ...state.menuItemClassifications, ...menuItemClassificationsMap };
    },

    [SET_MENU_ITEM_DETAIL]: (state, menuItemDetail) => {
        state.menuItemDetails = { ...state.menuItemDetails, [menuItemDetail.uuid]: menuItemDetail };
    },

    [SET_MENU_ITEM_DETAILS]: (state, menuItemDetails) => {
        const menuItemDetailsMap = keyBy(menuItemDetails, 'uuid');
        state.menuItemDetails = { ...state.menuItemDetails, ...menuItemDetailsMap };
    },

    [SET_MENU_ITEM_VARIANT]: (state, menuItemVariant) => {
        state.menuItemsVariants = { ...state.menuItemsVariants, [menuItemVariant.uuid]: menuItemVariant };
    },

    [SET_MENU_ITEMS_VARIANTS]: (state, menuItemsVariants) => {
        const menuItemsVariantsMap = keyBy(menuItemsVariants, 'uuid');
        state.menuItemsVariants = { ...state.menuItemsVariants, ...menuItemsVariantsMap };
    },

    [SET_MENU_ITEM_CATEGORIES]: (state, menuItemCategories) => {
        const menuItemCategoriesMap = keyBy(menuItemCategories, 'uuid');
        state.menuItemCategories = { ...state.menuItemCategories, ...menuItemCategoriesMap };
    },

    [SET_MENU_ITEM]: (state, menuItem) => {
        state.menuItems = { ...state.menuItems, [menuItem.uuid]: menuItem };
    },

    [SET_MENU_ITEMS]: (state, menuItems) => {
        const menuItemsMap = keyBy(menuItems, 'uuid');
        state.menuItems = { ...state.menuItems, ...menuItemsMap };
    },

    [SET_CATEGORY]: (state, category) => {
        state.categories = { ...state.categories, [category.uuid]: category };
    },

    [SET_CATEGORY_DETAILS]: (state, categoryDetails) => {
        const categoryDetailsMap = keyBy(categoryDetails, 'uuid');
        state.categoryDetails = { ...state.categoryDetails, ...categoryDetailsMap };
    },

    [SET_CATEGORY_VARIANT]: (state, categoryVariant) => {
        state.categoriesVariants = { ...state.categoriesVariants, [categoryVariant.uuid]: categoryVariant };
    },

    [SET_MENU_REQUEST_HELPERS]: (state, menuRequestHelpers) => {
        state.menuRequestHelpers = menuRequestHelpers;
    },

    [SET_MENU_VARIANT]: (state, menuVariant) => {
        state.menusVariants = { ...state.menusVariants, [menuVariant.uuid]: menuVariant };
    },

    [SET_MODIFIER]: (state, modifier) => {
        const oldModifier = state.modifierGroups[modifier.uuid] ?? {};
        state.modifiers = { ...state.modifiers, [modifier.uuid]: { ...oldModifier, ...modifier } };
    },

    [SET_MODIFIERS]: (state, modifiers) => {
        const modifiersMap = keyBy(modifiers, 'uuid');
        state.modifiers = { ...state.modifiers, ...modifiersMap };
    },

    [SET_MODIFIERS_VARIANTS]: (state, modifiersVariants) => {
        const modifiersVariantsMap = keyBy(modifiersVariants, 'uuid');
        state.modifiersVariants = { ...state.modifiersVariants, ...modifiersVariantsMap };
    },

    [SET_MODIFIER_DETAILS]: (state, modifierDetails) => {
        const modifierDetailsMap = keyBy(modifierDetails, 'uuid');
        state.modifierDetails = { ...state.modifierDetails, ...modifierDetailsMap };
    },

    [SET_MODIFIER_GROUP]: (state, modifierGroup) => {
        state.modifierGroups = { ...state.modifierGroups, [modifierGroup.uuid]: modifierGroup };
    },

    [SET_MODIFIER_GROUPS]: (state, modifierGroups) => {
        const modifierGroupsMap = keyBy(modifierGroups, 'uuid');
        state.modifierGroups = { ...state.modifierGroups, ...modifierGroupsMap };
    },

    [SET_MODIFIER_GROUPS_VARIANTS]: (state, modifierGroupsVariants) => {
        const modifierGroupsVariantsMap = keyBy(modifierGroupsVariants, 'uuid');
        state.modifierGroupsVariants = { ...state.modifierGroupsVariants, ...modifierGroupsVariantsMap };
    },

    [SET_MODIFIER_GROUP_DETAILS]: (state, modifierGroupDetails) => {
        const modifierGroupDetailsMap = keyBy(modifierGroupDetails, 'uuid');
        state.modifierGroupDetails = { ...state.modifierGroupDetails, ...modifierGroupDetailsMap };
    },

    [SET_MODIFIER_GROUP_VARIANT]: (state, modifierGroupVariant) => {
        state.modifierGroupsVariants = {
            ...state.modifierGroupsVariants,
            [modifierGroupVariant.uuid]: modifierGroupVariant,
        };
    },

    [SET_RESTAURANT_MENUS]: (state, restaurantMenus) => {
        const restaurantMenusMap = keyBy(restaurantMenus, 'uuid');
        state.restaurantMenus = { ...state.restaurantMenus, ...restaurantMenusMap };
    },

    [SET_RESTAURANT_STATES]: (state, restaurantStates) => {
        const restaurantStatesMap = keyBy(restaurantStates, 'uuid');
        state.restaurantStates = { ...state.restaurantStates, ...restaurantStatesMap };
    },

    [SET_VARIANT]: (state, variant) => {
        state.variants = { ...state.variants, [variant.uuid]: variant };
    },

    [SET_VARIANTS]: (state, variants) => {
        const variantsMap = keyBy(variants, 'uuid');
        state.variants = { ...state.variants, ...variantsMap };
    },

    [UPDATE_CATEGORY]: (state, { categoryUuid, categoryUpdatePayload }) => {
        state.categories[categoryUuid] = { ...state.categories[categoryUuid], ...categoryUpdatePayload };
    },

    [UPDATE_MENU_ITEM_VARIANT]: (state, { menuItemVariantUuid, menuItemVariantUpdatePayload }) => {
        state.menuItemsVariants = {
            ...state.menuItemsVariants,
            [menuItemVariantUuid]: {
                ...state.menuItemsVariants[menuItemVariantUuid],
                ...menuItemVariantUpdatePayload,
            },
        };
    },

    [UPDATE_CATEGORY_DETAIL]: (state, { categoryDetailUuid, categoryDetailUpdatePayload }) => {
        state.categoryDetails[categoryDetailUuid] = {
            ...state.categoryDetails[categoryDetailUuid],
            ...categoryDetailUpdatePayload,
        };
    },

    [UPDATE_MENU_DETAIL]: (state, { menuDetailUuid, menuDetailUpdatePayload }) => {
        state.menuDetails[menuDetailUuid] = { ...state.menuDetails[menuDetailUuid], ...menuDetailUpdatePayload };
    },

    [UPDATE_MENU_ITEM_DETAIL]: (state, { menuItemDetailUuid, menuItemDetailUpdatePayload }) => {
        state.menuItemDetails[menuItemDetailUuid] = {
            ...state.menuItemDetails[menuItemDetailUuid],
            ...menuItemDetailUpdatePayload,
        };
    },

    [UPDATE_MODIFIER_GROUP_DETAIL]: (state, { modifierGroupDetailUuid, modifierGroupDetailUpdatePayload }) => {
        state.modifierGroupDetails[modifierGroupDetailUuid] = {
            ...state.modifierGroupDetails[modifierGroupDetailUuid],
            ...modifierGroupDetailUpdatePayload,
        };
    },

    [UPDATE_MODIFIER_DETAIL]: (state, { modifierDetailUuid, modifierDetailUpdatePayload }) => {
        state.modifierDetails[modifierDetailUuid] = {
            ...state.modifierDetails[modifierDetailUuid],
            ...modifierDetailUpdatePayload,
        };
    },
};
