import { kitchenManagerClient, kitchenManagerV2 } from '@/apis/gatewayPlatformsClients';
import snakeize from 'snakeize';

const createConcept = (requestPayload) => {
    return kitchenManagerClient.createConcept(snakeize(requestPayload));
};

const fetchConcepts = (requestParams = {}) => {
    return kitchenManagerClient.listConcepts(requestParams);
};

const fetchCountries = ({ isDeleted = false } = {}) => kitchenManagerClient.listCountries({ isDeleted });

const fetchKitchens = (requestParams = {}) => {
    return kitchenManagerClient.listKitchens(requestParams);
};

const fetchKitchensOverview = (requestParams = {}) => {
    return kitchenManagerV2.searchKitchensOverview({ kitchenOverviewSearchRequest: { requestParams } });
};

const fetchLocations = (requestParams = {}) => {
    return kitchenManagerClient.listLocations(requestParams);
};

const fetchOpeningHours = ({ conceptUuid, kitchenUuid, isDeleted = false }) => {
    return kitchenManagerV2.getKitchenOpeningHours({ conceptUuid, isDeleted, kitchenUuid });
};

const updateOpeningHours = ({ kitchenUuid, openingHoursRequest }) => {
    return kitchenManagerV2.updateKitchenOpeningHours({
        kitchenUuid,
        openingHoursRequest,
    });
};

const fetchReplacementSchedules = ({ conceptUuid, kitchenUuid }) => {
    return kitchenManagerV2.getKitchenReplacementSchedule({ conceptUuid, kitchenUuid });
};

const updateReplacementSchedule = ({ kitchenUuid, replacementScheduleRequest }) => {
    return kitchenManagerV2.updateKitchenReplacementSchedule({
        kitchenUuid,
        replacementScheduleRequest,
    });
};

const fetchPlatforms = (requestParams = {}) => {
    return kitchenManagerClient.listPlatforms(requestParams);
};

const fetchRestaurants = ({
    conceptUuid,
    isInternal,
    kitchenUuid,
    locationUuid,
    platformUuid,
    isActive,
    isDeleted = false,
    isPosIntegrated,
} = {}) => {
    return kitchenManagerClient.listRestaurants({
        conceptUuid,
        isActive,
        isDeleted,
        isInternal,
        isPosIntegrated,
        kitchenUuid,
        locationUuid,
        platformUuid,
    });
};

const uploadConceptPicture = (conceptUuid, picture) => {
    return kitchenManagerClient.uploadConceptPicture(conceptUuid, picture);
};

export const kitchenManagerApi = {
    createConcept,
    fetchConcepts,
    fetchCountries,
    fetchKitchens,
    fetchKitchensOverview,
    fetchLocations,
    fetchOpeningHours,
    fetchReplacementSchedules,
    fetchPlatforms,
    fetchRestaurants,
    updateOpeningHours,
    updateReplacementSchedule,
    uploadConceptPicture,
};
