import actions from '@/store/modules/ui/filters/reporting/actions';
import mutations from '@/store/modules/ui/filters/reporting/mutations';
import getters from '@/store/modules/ui/filters/reporting/getters';

const getInitialState = () => ({
    dateFilter: {
        aggregation: 'DAILY',
        currentDateRange: {
            endDate: null,
            startDate: null,
        },
        isCompareEnabled: false,
        previousDateRange: {
            endDate: null,
            startDate: null,
        },
    },
    kitchenTypes: ['taster', 'partners'],
    selectedConceptUuids: [],
    selectedKitchenLocationUuids: [],
    selectedPlatformUuids: [],
    showDeletedKitchens: false,
});

export default {
    namespaced: true,
    state: {
        temporary: getInitialState(),
        current: getInitialState(),
    },
    mutations,
    actions,
    getters,
};
