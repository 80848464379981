const getSelectedKitchenLocationUuids = (state) => state.selectedKitchenLocationUuids;

const getSelectedKitchenTypes = (state) => state.kitchenTypes;

const getShowDeletedKitchens = (state) => state.showDeletedKitchens;

export const getFilteredKitchensLocationUuids = (_state, getters, _rootState, rootGetters) => {
    const kitchensSortedByLabels = rootGetters['oneBoard/getKitchensSortedByLabels'];
    const showDeletedKitchens = getters.getShowDeletedKitchens;
    const selectedKitchenTypes = getters.getSelectedKitchenTypes;

    let filteredKitchens = [...kitchensSortedByLabels];

    if (!selectedKitchenTypes.length) {
        filteredKitchens = [];
    } else if (selectedKitchenTypes.length === 1) {
        const kitchenType = selectedKitchenTypes[0];
        filteredKitchens = kitchensSortedByLabels.filter(({ is_partner }) =>
            kitchenType === 'taster' ? !is_partner : is_partner
        );
    }

    filteredKitchens = showDeletedKitchens
        ? filteredKitchens
        : filteredKitchens.filter(({ is_deleted }) => !is_deleted);

    return filteredKitchens.map(({ location_uuid }) => location_uuid);
};

export default {
    getFilteredKitchensLocationUuids,
    getSelectedKitchenLocationUuids,
    getSelectedKitchenTypes,
    getShowDeletedKitchens,
};
