import { roles } from './roles';
const Invoices = () => import(/* webpackChunkName: "one-board" */ '@/views/Invoices/Invoices');

export default {
    display: true,
    path: '/invoices',
    keyName: 'invoices',
    name: 'invoices',
    component: Invoices,
    icon: 'fa-solid fa-file-invoice',
    meta: { authorize: { roles: [roles.InvoicesViewer, roles.RestaurantRevenue] }, title: 'menu.invoices' },
};
