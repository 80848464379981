<template>
    <PageLayout>
        <SnackbarAlert />
        <v-overlay color="transparent" :value="isLoading" absolute>
            <v-progress-circular indeterminate color="primary" size="64" />
        </v-overlay>

        <template v-if="!isLoading">
            <router-view v-if="activeKitchens.length && concepts.length" />

            <div v-else class="error-container">
                <v-icon x-large color="primary" class="mb-3"> fa-utensils </v-icon>
                <div v-if="activeKitchens.length === 0">
                    {{ $t('shared.noKitchen') }}
                </div>
                <div v-if="concepts.length === 0">
                    {{ $t('shared.noConcept') }}
                </div>
                <div>{{ $t('shared.noRightSubText') }}</div>
            </div>
        </template>
    </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SnackbarAlert from '@/components/Utils/SnackbarAlert';
import PageLayout from '@/components/PageLayout';

export default {
    components: { SnackbarAlert, PageLayout },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('oneBoard', {
            activeKitchens: 'getActiveKitchensSortedByLabels',
            concepts: 'getConcepts',
        }),
    },
    async created() {
        this.isLoading = true;
        this.fetchLiveStatuses();
        try {
            await Promise.all([this.fetchConcepts(), this.fetchKitchens(), this.fetchPlatforms()]);
        } catch (error) {
            this.$errorReporting.onError(error);
        } finally {
            this.isLoading = false;

            if (window.dataLayer) {
                const { email, sub } = this.$auth.user;
                window.dataLayer.push({
                    event: 'oneboard_log-connexion',
                    user_uuid: sub,
                    user_email: email,
                    kitchen_labels: this.activeKitchens.map(({ label }) => label),
                });
            }
        }
    },
    methods: {
        ...mapActions('oneBoard', ['fetchKitchens', 'fetchConcepts', 'fetchPlatforms', 'fetchLiveStatuses']),
    },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.wrapper {
    height: 100%;
}

.error-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-main > ::v-deep .v-main__wrap {
        position: unset;
    }
}
</style>
