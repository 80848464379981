export const ADD_CATEGORY_TO_MENU = 'ADD_CATEGORY_TO_MENU';
export const ADD_MENU_ELEMENT_TO_CATEGORY = 'ADD_MENU_ELEMENT_TO_CATEGORY';
export const DELETE_MODIFIER_GROUP = 'DELETE_MODIFIER_GROUP';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_NUTRITIONAL_CLASSIFICATIONS = 'SET_NUTRITIONAL_CLASSIFICATIONS';
export const SET_CATEGORIES_VARIANTS = 'SET_CATEGORIES_VARIANTS';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGORY_DETAILS = 'SET_CATEGORY_DETAILS';
export const SET_CATEGORY_VARIANT = 'SET_CATEGORY_VARIANT';
export const SET_MENU = 'SET_MENU';
export const SET_MENUS_VARIANTS = 'SET_MENUS_VARIANTS';
export const SET_MENU_ELEMENT_CUSTOMER_DISPLAY = 'SET_MENU_ELEMENT_CUSTOMER_DISPLAY';
export const SET_MENU_DETAILS = 'SET_MENU_DETAILS';
export const SET_MENU_ELEMENT = 'SET_MENU_ELEMENT';
export const SET_MENU_ELEMENTS = 'SET_MENU_ELEMENTS';
export const SET_MENU_ITEM = 'SET_MENU_ITEM';
export const SET_MENU_ITEMS = 'SET_MENU_ITEMS';
export const SET_MENU_ITEMS_VARIANTS = 'SET_MENU_ITEMS_VARIANTS';
export const SET_MENU_ITEM_CATEGORIES = 'SET_MENU_ITEM_CATEGORIES';
export const SET_MENU_ITEM_CLASSIFICATIONS = 'SET_MENU_ITEM_CLASSIFICATIONS';
export const SET_MENU_ITEM_DETAIL = 'SET_MENU_ITEM_DETAIL';
export const SET_MENU_ITEM_DETAILS = 'SET_MENU_ITEM_DETAILS';
export const SET_MENU_ITEM_VARIANT = 'SET_MENU_ITEM_VARIANT';
export const SET_MENU_REQUEST_HELPERS = 'SET_MENU_REQUEST_HELPERS';
export const SET_MENU_VARIANT = 'SET_MENU_VARIANT';
export const SET_MODIFIER = 'SET_MODIFIER';
export const SET_MODIFIERS = 'SET_MODIFIERS';
export const SET_MODIFIERS_VARIANTS = 'SET_MODIFIERS_VARIANTS';
export const SET_MODIFIER_DETAILS = 'SET_MODIFIER_DETAILS';
export const SET_MODIFIER_GROUP = 'SET_MODIFIER_GROUP';
export const SET_MODIFIER_GROUPS = 'SET_MODIFIER_GROUPS';
export const SET_MODIFIER_GROUPS_VARIANTS = 'SET_MODIFIER_GROUPS_VARIANTS';
export const SET_MODIFIER_GROUP_DETAILS = 'SET_MODIFIER_GROUP_DETAILS';
export const SET_MODIFIER_GROUP_VARIANT = 'SET_MODIFIER_GROUP_VARIANT';
export const SET_RESTAURANT_MENUS = 'SET_RESTAURANT_MENUS';
export const SET_RESTAURANT_STATES = 'SET_RESTAURANT_STATES';
export const SET_VARIANT = 'SET_VARIANT';
export const SET_VARIANTS = 'SET_VARIANTS';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_DETAIL = 'UPDATE_CATEGORY_DETAIL';
export const UPDATE_MENU_DETAIL = 'UPDATE_MENU_DETAIL';
export const UPDATE_MENU_ITEM_DETAIL = 'UPDATE_MENU_ITEM_DETAIL';
export const UPDATE_MENU_ITEM_VARIANT = 'UPDATE_MENU_ITEM_VARIANT';
export const UPDATE_MODIFIER_DETAIL = 'UPDATE_MODIFIER_DETAIL';
export const UPDATE_MODIFIER_GROUP_DETAIL = 'UPDATE_MODIFIER_GROUP_DETAIL';
