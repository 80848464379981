import locations from './locations';
import metrics from './metrics';
import reviews from './reviews';
import missingItemsOrders from './missingItemsOrders';

export default {
    ...locations,
    ...metrics,
    ...reviews,
    ...missingItemsOrders,
};
