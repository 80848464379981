import Vue from 'vue';

export const clientHook = function (client) {
    client.apiClient.defaultHeaders = {};
    if (Object.keys(client.apiClient.authentications).includes('OAuth2')) {
        client.apiClient.authentications['OAuth2']['accessToken'] = Vue.prototype.$auth.getToken();
    }
    if (Object.keys(client.apiClient.authentications).includes('oauth2')) {
        client.apiClient.authentications['oauth2']['accessToken'] = Vue.prototype.$auth.getToken();
    }
    return client;
};

export default clientHook;

// Method to fix safari issue when rendering date from API
//github.com/OpenAPITools/openapi-generator/issues/9523

export const parseDate = function (str) {
    return new Date(isNaN(str) ? str : +str);
};
