import oneBoardApi from '@/apis/oneBoard';

import {
    SET_QUALITY_SPLIT_CHART_BY_CONCEPT,
    SET_QUALITY_SUMMARY,
    SET_QUALITY_TIME_SERIES_BY_CONCEPT,
    SET_QUALITY_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/quality/mutationTypes';

const fetchQualitySplitChartByConcept = async ({ commit }, payload) => {
    const splitChartByConcept = await oneBoardApi.fetchQualitySplitChart({
        ...payload,
        splitBy: 'concept',
    });
    commit(SET_QUALITY_SPLIT_CHART_BY_CONCEPT, splitChartByConcept);
};

const fetchQualitySummary = async ({ commit }, payload) => {
    const summary = await oneBoardApi.fetchQualitySummary(payload);
    commit(SET_QUALITY_SUMMARY, summary);
};

const fetchQualityTimeseriesByConcept = async ({ commit }, payload) => {
    const timeseriesByConcept = await oneBoardApi.fetchQualityTimeseries({
        ...payload,
        splitBy: 'concept',
    });
    commit(SET_QUALITY_TIME_SERIES_BY_CONCEPT, timeseriesByConcept);
};

const fetchQualityTimeseriesByLocation = async ({ commit }, payload) => {
    const timeseriesByLocation = await oneBoardApi.fetchQualityTimeseries({
        ...payload,
        splitBy: 'location',
    });
    commit(SET_QUALITY_TIME_SERIES_BY_LOCATION, timeseriesByLocation);
};

const fetchQualityData = async ({ dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'quality', isLoading: true }, { root: true });
    try {
        await Promise.all([
            dispatch('fetchQualitySummary', payload),
            dispatch('fetchQualitySplitChartByConcept', payload),
            dispatch('fetchQualityTimeseriesByConcept', payload),
            dispatch('fetchQualityTimeseriesByLocation', payload),
        ]);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'quality', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'quality', isLoading: false }, { root: true });
    }
};

export default {
    fetchQualityData,
    fetchQualitySplitChartByConcept,
    fetchQualitySummary,
    fetchQualityTimeseriesByConcept,
    fetchQualityTimeseriesByLocation,
};
