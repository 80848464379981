import actions from '@/store/modules/menuManager/actions';
import getters from '@/store/modules/menuManager/getters';
import mutations from '@/store/modules/menuManager/mutations';

const menuRequestHelpersInitialState = {
    conceptUuid: null,
    currency: null,
    defaultVariantUuid: null,
    languageCode: null,
    menuUuid: null,
};

export const menuManager = {
    namespaced: true,
    state: {
        nutritionalClassifications: {},
        categories: {},
        categoriesVariants: {},
        categoryDetails: {},
        menuDetails: {},
        menuElementCustomerDisplay: {},
        menuElements: {},
        menuItemCategories: null,
        menuItemClassifications: null,
        menuItemDetails: {},
        menuItems: {},
        menuItemsVariants: {},
        menuRequestHelpers: menuRequestHelpersInitialState,
        menus: {},
        menusVariants: {},
        modifierDetails: {},
        modifierGroupDetails: {},
        modifierGroups: {},
        modifierGroupsVariants: {},
        modifiers: {},
        modifiersVariants: {},
        restaurantMenus: {},
        restaurantStates: {},
        variants: {},
    },
    actions,
    getters,
    mutations,
};
