export const APPLY_TEMPORARY_FILTERS_TO_CURRENT = 'APPLY_TEMPORARY_FILTERS_TO_CURRENT';
export const RESET_TEMPORARY_FILTERS = 'RESET_TEMPORARY_FILTERS';
export const SET_AGGREGATION = 'SET_AGGREGATION';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_SELECTED_CONCEPT_UUIDS = 'SET_SELECTED_CONCEPT_UUIDS';
export const SET_SELECTED_KITCHEN_LOCATION_UUIDS = 'SET_SELECTED_KITCHEN_LOCATION_UUIDS';
export const SET_SELECTED_PLATFORM_UUIDS = 'SET_SELECTED_PLATFORM_UUIDS';
export const SET_TEMPORARY_CONCEPT_UUIDS = 'SET_TEMPORARY_CONCEPT_UUIDS';
export const SET_TEMPORARY_DATE_FILTER = 'SET_TEMPORARY_DATE_FILTER';
export const SET_TEMPORARY_KITCHEN_LOCATION_UUIDS = 'SET_TEMPORARY_KITCHEN_LOCATION_UUIDS';
export const SET_TEMPORARY_KITCHEN_TYPES = 'SET_TEMPORARY_KITCHEN_TYPES';
export const SET_TEMPORARY_PLATFORMS_UUIDS = 'SET_TEMPORARY_PLATFORMS_UUIDS';
export const TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS = 'TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS';
