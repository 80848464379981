import { platformStateClient } from '@/apis/gatewayPlatformsClients';
import snakeize from 'snakeize';

const createMenuVersion = ({ generatedMenuUuid: generated_menu_uuid, restaurantStateUuid: restaurant_state_uuid }) => {
    return platformStateClient.createMenuVersion({ generated_menu_uuid, restaurant_state_uuid });
};

const createMenuVersions = (menuCollection) => {
    return platformStateClient.createMenuVersions(menuCollection);
};

const fetchMenuVersion = (menuVersionUuid, restaurantStateUuid) =>
    platformStateClient.getMenuVersion(menuVersionUuid, restaurantStateUuid);

const getMenuInformation = ({
    conceptUuid,
    locationUuid,
    countryUuid,
    platformUuid,
    restaurantUuid,
    restaurantPlatformId,
    unavailabilities,
}) => {
    return platformStateClient.getMenuInformation(
        snakeize({
            conceptUuid,
            locationUuid,
            countryUuid,
            platformUuid,
            restaurantUuid,
            restaurantPlatformId,
            unavailabilities,
        })
    );
};

const listItemStatusVersions = ({
    conceptUuid,
    locationUuid,
    countryUuid,
    platformUuid,
    restaurantUuid,
    restaurantPlatformId,
    unavailabilities,
    status,
    current = null,
    last = true,
}) => {
    return platformStateClient.listItemStatusVersions({
        conceptUuid,
        locationUuid,
        countryUuid,
        platformUuid,
        restaurantUuid,
        restaurantPlatformId,
        unavailabilities,
        status,
        current,
        last,
    });
};

const createItemStatusVersion = ({ restaurantStateUuid, itemUuid, status }) => {
    return platformStateClient.createItemStatusVersion(snakeize({ restaurantStateUuid, itemUuid, status }));
};

const getItemStatusVersion = ({ restaurantStateUuid, itemStatusVersionUuid }) => {
    return platformStateClient.getItemStatusVersion(restaurantStateUuid, itemStatusVersionUuid);
};

const fetchMenuVersions = ({
    conceptUuid,
    current,
    locationUuid,
    platformUuid,
    restaurantStateUuid,
    restaurantUuid,
    status,
} = {}) => {
    return platformStateClient.listMenuVersions({
        conceptUuid,
        current,
        locationUuid,
        platformUuid,
        restaurantStateUuid,
        restaurantUuid,
        status,
    });
};

const fetchRestaurantStates = ({ conceptUuid, locationUuid, platformUuid, restaurantUuid, isDeleted = false } = {}) => {
    return platformStateClient.listRestaurantStates({
        conceptUuid,
        isDeleted,
        locationUuid,
        platformUuid,
        restaurantUuid,
    });
};

const fetchPublicationStates = (stateType, requestPayload) =>
    platformStateClient.listPublicationStates(stateType, requestPayload);

const createRestaurantStatusVersion = ({ restaurantStateUuid, status }) => {
    return platformStateClient.createRestaurantStatusVersion(snakeize({ restaurantStateUuid, status }));
};

const getRestaurantStatusVersions = ({
    conceptUuid,
    locationUuid,
    platformUuid,
    restaurantStateUuid,
    restaurantUuid,
    status,
    current = null,
    last = true,
}) => {
    return platformStateClient.listRestaurantStatusVersions({
        conceptUuid,
        current,
        locationUuid,
        platformUuid,
        restaurantStateUuid,
        restaurantUuid,
        status,
        last,
    });
};

export const platformStateApi = {
    createItemStatusVersion,
    createMenuVersion,
    createMenuVersions,
    createRestaurantStatusVersion,
    fetchMenuVersion,
    fetchMenuVersions,
    fetchPublicationStates,
    fetchRestaurantStates,
    getItemStatusVersion,
    getMenuInformation,
    getRestaurantStatusVersions,
    listItemStatusVersions,
};
