import actions from '@/store/modules/notifier/actions';
import mutations from '@/store/modules/notifier/mutations';

export const notifier = {
    namespaced: true,
    state: {
        message: '',
        subText: '',
        type: 'success',
        position: '',
    },
    actions,
    mutations,
};
