import oneBoardApi from '@/apis/oneBoard';
import {
    SET_FINANCE_COMPARISON_SUMMARY,
    SET_FINANCE_SUMMARY,
    SET_FINANCE_TIME_CHART,
    SET_FINANCE_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/finance/mutationTypes';
import { getComparisonAggregation } from '@/utils/dateHelpers';

const fetchFinanceTimeChart = async ({ commit }, payload) => {
    const timeChart = await oneBoardApi.fetchFinanceTimeChart(payload);
    commit(SET_FINANCE_TIME_CHART, timeChart);
};

const fetchFinanceSummary = async ({ commit }, payload) => {
    const summary = await oneBoardApi.fetchFinanceSummary(payload);
    commit(SET_FINANCE_SUMMARY, summary);
};

const fetchFinanceComparisonSummary = async ({ commit, rootState }, payload) => {
    const { endDate, startDate } = rootState.ui.filters.reporting.current.dateFilter.previousDateRange;
    const aggregation = getComparisonAggregation(startDate, endDate);

    const comparisonSummary = await oneBoardApi.fetchFinanceSummary({
        ...payload,
        aggregation,
        endDate,
        startDate,
    });
    commit(SET_FINANCE_COMPARISON_SUMMARY, comparisonSummary);
};

const fetchFinanceTimeseriesByLocation = async ({ commit }, payload) => {
    const timeseriesByLocation = await oneBoardApi.fetchFinanceTimeseries({
        ...payload,
        splitBy: 'location',
    });
    commit(SET_FINANCE_TIME_SERIES_BY_LOCATION, timeseriesByLocation);
};

const fetchFinanceData = async ({ dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'finance', isLoading: true }, { root: true });
    try {
        const actionsToDispatch = ['fetchFinanceSummary', 'fetchFinanceTimeChart', 'fetchFinanceTimeseriesByLocation'];

        if (payload.isCompareEnabled) {
            actionsToDispatch.push('fetchFinanceComparisonSummary');
        }

        await Promise.all(actionsToDispatch.map((actionName) => dispatch(actionName, payload)));
    } catch (error) {
        dispatch('status/setError', { stateKey: 'finance', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'finance', isLoading: false }, { root: true });
    }
};

export default {
    fetchFinanceComparisonSummary,
    fetchFinanceData,
    fetchFinanceSummary,
    fetchFinanceTimeChart,
    fetchFinanceTimeseriesByLocation,
};
