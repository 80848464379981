<template>
    <v-btn color="dark" icon :ripple="false" class="text-subtitle-1" text @click="logout">
        <v-icon>logout</v-icon>
    </v-btn>
</template>

<script>
export default {
    methods: {
        logout() {
            this.$auth.logout();
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .v-btn__content {
    color: rgba(0, 0, 0, 0.56);
    display: flex;
    gap: 8px;
}
</style>
