const getComparisonSummary = (state) => state.comparisonSummary;
const getSplitChartByConcept = (state) => state.splitChartByConcept;
const getSplitChartByPlatform = (state) => state.splitChartByPlatform;
const getSummary = (state) => state.summary;
const getTimeChart = (state) => state.timeChart;
const getTimeseriesByLocation = (state) => state.timeseriesByLocation;

export default {
    getComparisonSummary,
    getSplitChartByConcept,
    getSplitChartByPlatform,
    getSummary,
    getTimeChart,
    getTimeseriesByLocation,
};
