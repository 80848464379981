import { hasRole, roles } from '@/router/roles';

const MenuManagerMenuListLayout = () =>
    import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuManagerMenuListLayout.vue');
const HomepageLayout = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/HomepageLayout.vue');
const MenuEditorLayout = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuEditorLayout.vue');
const MenuItemCreate = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuItemCreate.vue');
const MenuItemEdit = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuItemEdit.vue');
const MenuManagerLayout = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuManagerLayout.vue');
const MenuManagerRestaurantsList = () =>
    import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuManagerRestaurantsList.vue');
const MenuManagerMenuEditor = () =>
    import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuManagerMenuEditor.vue');
const ConceptSelectForm = () =>
    import(/* webpackChunkName: "one-board" */ '@/components/MenuManager/ConceptSelectForm');
const CreateMenuStepper = () =>
    import(/* webpackChunkName: "one-board" */ '@/components/MenuManager/CreateMenuStepper');
const MenuCreateForm = () => import(/* webpackChunkName: "one-board" */ '@/components/MenuManager/MenuCreateForm');
const MenuPublish = () => import(/* webpackChunkName: "one-board" */ '@/views/MenuManager/MenuPublish');

const beforeEnterHomepageChildren = (to, from, next) => {
    const tabs = [
        {
            hasRole: hasRole(roles.MenuManagerTemplatesViewer) || hasRole(roles.LocalizedMenuManagerUser),
            to: { name: 'menuManagerMenuListLayout' },
        },
        {
            hasRole:
                hasRole(roles.MenuManagerRestaurantsViewer) ||
                hasRole(roles.RestaurantLiveViewer) ||
                hasRole(roles.LocalizedMenuManagerUser),
            to: { name: 'menuManagerRestaurantsList' },
        },
    ].filter((tab) => tab.hasRole);

    tabs.some((tab) => tab.to.name === to.name) ? next() : next(tabs[0].to);
};

export const menuManager = {
    component: MenuManagerLayout,
    display: true,
    icon: 'fa-solid fa-utensils',
    keyName: 'menuManager',
    meta: {
        authorize: { roles: [roles.MenuManagerViewer, roles.RestaurantLiveViewer, roles.LocalizedMenuManagerUser] },
    },
    path: '/menu-manager',
    children: [
        {
            component: HomepageLayout,
            meta: {
                authorize: {
                    roles: [roles.MenuManagerViewer, roles.RestaurantLiveViewer, roles.LocalizedMenuManagerUser],
                },
            },
            path: '',
            redirect: { name: 'menuManagerMenuListLayout' },
            name: 'menu-manager-homepage',
            children: [
                {
                    beforeEnter: beforeEnterHomepageChildren,
                    component: MenuManagerMenuListLayout,
                    name: 'menuManagerMenuListLayout',
                    path: 'menus',
                    meta: {
                        title: 'menu.menuManager',
                    },
                    children: [
                        {
                            name: 'selectConcept',
                            path: 'select-concept',
                            component: ConceptSelectForm,
                            meta: {
                                authorize: {
                                    roles: [roles.MenuManagerMenuCreator, roles.LocalizedMenuManagerUser],
                                },
                                showDialog: true,
                            },
                        },
                        {
                            name: 'createNewConcept',
                            path: 'new-concept',
                            component: CreateMenuStepper,
                            meta: {
                                authorize: {
                                    roles: [roles.MenuManagerMenuCreator],
                                },
                                showDialog: true,
                            },
                        },
                        {
                            beforeEnter: (to, from, next) => {
                                to.params.conceptUuid ? next() : next({ name: 'menuManagerMenuListLayout' });
                            },
                            name: 'selectPlatforms',
                            path: 'select-platforms/:conceptUuid',
                            props: true,
                            component: MenuCreateForm,
                            meta: {
                                authorize: {
                                    roles: [roles.MenuManagerMenuCreator, roles.LocalizedMenuManagerUser],
                                },
                                showDialog: true,
                                meta: {
                                    title: 'menu.menuManager',
                                },
                            },
                        },
                    ],
                },
                {
                    beforeEnter: beforeEnterHomepageChildren,
                    component: MenuManagerRestaurantsList,
                    name: 'menuManagerRestaurantsList',
                    path: 'restaurants/:conceptUuid?',
                },
            ],
        },
        {
            beforeEnter: (to, from, next) => {
                to.params.conceptUuid && to.params.menuUuid ? next() : next({ name: 'menuManagerMenuListLayout' });
            },
            component: MenuEditorLayout,
            meta: {
                authorize: {
                    roles: [roles.MenuManagerTemplatesViewer, roles.LocalizedMenuManagerUser],
                },
            },
            path: 'menu-editor/:conceptUuid?/:menuUuid?',
            props: true,
            children: [
                {
                    component: MenuManagerMenuEditor,
                    meta: {
                        authorize: { roles: [roles.MenuManagerTemplatesViewer, roles.LocalizedMenuManagerUser] },
                        scrollPosition: {
                            top: 0,
                            left: 0,
                        },
                    },
                    name: 'menuEditor',
                    path: '',
                },
                {
                    component: MenuItemCreate,
                    meta: { authorize: { roles: [roles.MenuManagerTemplatesViewer, roles.LocalizedMenuManagerUser] } },
                    name: 'menuItemCreate',
                    path: 'create-item',
                },
                {
                    component: MenuItemEdit,
                    meta: { authorize: { roles: [roles.MenuManagerTemplatesViewer, roles.LocalizedMenuManagerUser] } },
                    name: 'menuItemEdit',
                    props: true,
                    path: 'edit-item/:menuItemUuid',
                },
                {
                    component: MenuPublish,
                    meta: {
                        authorize: {
                            roles: [roles.MenuManagerMenuPublisher, roles.LocalizedMenuManagerUser],
                        },
                    },
                    name: 'menuPublish',
                    props: true,
                    path: 'publish',
                },
            ],
        },
    ],
};
