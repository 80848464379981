import { metricsMissingItemsApi } from '@/apis/oneBoard/clients';
import snakeize from 'snakeize';

export default {
    fetchMissingItemsTimeSeries(
        startDate,
        endDate,
        { splitBy = 'location', locationUuids, aggregation, conceptUuids, platformUuids, countryCodes }
    ) {
        return metricsMissingItemsApi.getMetricsMissingItemsOrdersTimeseriesChart(
            snakeize({
                startDate,
                endDate,
                splitBy,
                locationUuids,
                aggregation,
                conceptUuids,
                platformUuids,
                countryCodes,
            })
        );
    },
};
