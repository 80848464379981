<template>
    <PageLayout>
        <div class="unauthorized">
            <div class="emoji-sad">😥</div>
            <div>
                {{ $t('shared.unauthorized') }}
            </div>
            <v-btn @click="logout">
                {{ $t('shared.logout') }}
            </v-btn>
        </div>
    </PageLayout>
</template>

<script>
import PageLayout from '@/components/PageLayout';

export default {
    components: { PageLayout },
    methods: {
        logout() {
            this.$auth.logout();
        },
    },
};
</script>

<style scoped lang="scss">
.unauthorized {
    margin-top: 50vh;
    transform: translateY(-50%);
    text-align: center;
    font-size: 2em;
    font-weight: 600;
}
.emoji-sad {
    font-size: 100px;
}
</style>
