import Vue from 'vue';
import { SET_ERROR, SET_LOADING_STATUS } from '@/store/modules/status/mutationTypes';

export default {
    [SET_ERROR]: (state, { stateKey, error }) => {
        if (typeof state[stateKey] === 'undefined') {
            Vue.set(state, stateKey, { error });
        } else {
            Vue.set(state[stateKey], 'error', error);
        }
    },
    [SET_LOADING_STATUS]: (state, { stateKey, isLoading }) => {
        if (typeof state[stateKey] === 'undefined') {
            Vue.set(state, stateKey, { isLoading });
        } else {
            Vue.set(state[stateKey], 'isLoading', isLoading);
        }
    },
};
