import Vue from 'vue';
import { PublishedInvoicesApi } from '@tasterkitchens/invoices-manager-client';

const invoiceApi = new PublishedInvoicesApi({
    accessToken: () => Vue.prototype.$auth.getToken(),
    isJsonMime: () => true,
    basePath: process.env.VUE_APP_INVOICES_API_URL,
});

export const getPublishedInvoices = async ({ startDate, endDate, locationUuids, isDeleted }) => {
    return await invoiceApi.listPublishedInvoices(startDate, endDate, locationUuids, isDeleted);
};
