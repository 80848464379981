import {
    SET_PERFORMANCE_COMPARISON_SUMMARY,
    SET_PERFORMANCE_SPLIT_CHART_BY_CONCEPT,
    SET_PERFORMANCE_SPLIT_CHART_BY_PLATFORM,
    SET_PERFORMANCE_SUMMARY,
    SET_PERFORMANCE_TIME_CHART,
    SET_PERFORMANCE_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/performance/mutationTypes';
import oneBoardApi from '@/apis/oneBoard';
import { getComparisonAggregation } from '@/utils/dateHelpers';

const fetchPerformanceSplitByConcept = async ({ commit }, payload) => {
    const splitChartByConcept = await oneBoardApi.fetchPerformanceSplitChart({
        ...payload,
        splitBy: 'concept',
    });
    commit(SET_PERFORMANCE_SPLIT_CHART_BY_CONCEPT, splitChartByConcept);
};

const fetchPerformanceSplitByPlatform = async ({ commit }, payload) => {
    const splitChartByPlatform = await oneBoardApi.fetchPerformanceSplitChart({
        ...payload,
        splitBy: 'platform',
    });
    commit(SET_PERFORMANCE_SPLIT_CHART_BY_PLATFORM, splitChartByPlatform);
};

const fetchPerformanceTimeChart = async ({ commit }, payload) => {
    const timeChart = await oneBoardApi.fetchPerformanceTimeChart(payload);
    commit(SET_PERFORMANCE_TIME_CHART, timeChart);
};

const fetchPerformanceSummary = async ({ commit }, payload) => {
    const summary = await oneBoardApi.fetchPerformanceSummary(payload);
    commit(SET_PERFORMANCE_SUMMARY, summary);
};

const fetchPerformanceComparisonSummary = async ({ commit, rootState }, payload) => {
    const { endDate, startDate } = rootState.ui.filters.reporting.current.dateFilter.previousDateRange;
    const aggregation = getComparisonAggregation(startDate, endDate);

    const comparisonSummary = await oneBoardApi.fetchPerformanceSummary({
        ...payload,
        aggregation,
        endDate,
        startDate,
    });
    commit(SET_PERFORMANCE_COMPARISON_SUMMARY, comparisonSummary);
};

const fetchPerformanceTimeseriesByLocation = async ({ commit }, payload) => {
    const timeseriesByLocation = await oneBoardApi.fetchPerformanceTimeseries({
        ...payload,
        splitBy: 'location',
    });
    commit(SET_PERFORMANCE_TIME_SERIES_BY_LOCATION, timeseriesByLocation);
};

const fetchPerformanceData = async ({ dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'performance', isLoading: true }, { root: true });
    try {
        const actionsToDispatch = [
            'fetchPerformanceSummary',
            'fetchPerformanceSplitByConcept',
            'fetchPerformanceSplitByPlatform',
            'fetchPerformanceTimeChart',
            'fetchPerformanceTimeseriesByLocation',
        ];

        if (payload.isCompareEnabled) {
            actionsToDispatch.push('fetchPerformanceComparisonSummary');
        }

        await Promise.all(actionsToDispatch.map((actionName) => dispatch(actionName, payload)));
    } catch (error) {
        dispatch('status/setError', { stateKey: 'performance', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'performance', isLoading: false }, { root: true });
    }
};

export default {
    fetchPerformanceComparisonSummary,
    fetchPerformanceData,
    fetchPerformanceSplitByConcept,
    fetchPerformanceSplitByPlatform,
    fetchPerformanceSummary,
    fetchPerformanceTimeChart,
    fetchPerformanceTimeseriesByLocation,
};
