const getIsCompareEnabled = (state) => state.current.dateFilter.isCompareEnabled;

const getSelectedAggregation = (state) => state.current.dateFilter.aggregation;

const getSelectedConceptUuids = (state) => state.current.selectedConceptUuids;

const getSelectedCurrentDateRange = (state) => state.current.dateFilter.currentDateRange;

const getSelectedDateFilter = (state) => state.current.dateFilter;

const getSelectedKitchenLocationUuids = (state) => state.current.selectedKitchenLocationUuids;

const getSelectedKitchenTypes = (state) => state.current.kitchenTypes;

const getSelectedPlatformUuids = (state) => state.current.selectedPlatformUuids;

const getShowDeletedKitchens = (state) => state.current.showDeletedKitchens;

const getTemporaryKitchenLocationUuids = (state) => state.temporary.selectedKitchenLocationUuids;

const getTemporaryKitchenTypes = (state) => state.temporary.kitchenTypes;

const getTemporaryShowDeletedKitchens = (state) => state.temporary.showDeletedKitchens;

const getTemporaryConceptUuids = (state) => state.temporary.selectedConceptUuids;

const getTemporaryCurrentDateRange = (state) => state.temporary.dateFilter.currentDateRange;

const getTemporaryPlatformUuids = (state) => state.temporary.selectedPlatformUuids;

export const getFilteredKitchens = (_state, getters, _rootState, rootGetters) => {
    const kitchensSortedByLabels = rootGetters['oneBoard/getKitchensSortedByLabels'];
    const showDeletedKitchens = getters.getShowDeletedKitchens;
    const selectedKitchenTypes = getters.getSelectedKitchenTypes;

    let filteredKitchens = [...kitchensSortedByLabels];
    if (!selectedKitchenTypes.length) {
        filteredKitchens = [];
    } else if (selectedKitchenTypes.length === 1) {
        const kitchenType = selectedKitchenTypes[0];
        filteredKitchens = kitchensSortedByLabels.filter(({ is_partner }) =>
            kitchenType === 'taster' ? !is_partner : is_partner
        );
    }

    return showDeletedKitchens ? filteredKitchens : filteredKitchens.filter(({ is_deleted }) => !is_deleted);
};

export default {
    getFilteredKitchens,
    getIsCompareEnabled,
    getSelectedAggregation,
    getSelectedConceptUuids,
    getSelectedCurrentDateRange,
    getSelectedDateFilter,
    getSelectedKitchenLocationUuids,
    getSelectedKitchenTypes,
    getSelectedPlatformUuids,
    getShowDeletedKitchens,
    getTemporaryConceptUuids,
    getTemporaryCurrentDateRange,
    getTemporaryKitchenLocationUuids,
    getTemporaryKitchenTypes,
    getTemporaryPlatformUuids,
    getTemporaryShowDeletedKitchens,
};
