import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

import auth from '@/auth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';
import errorReporting from '@/plugins/errorReporting';
import './registerServiceWorker';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dataLayer from '@/plugins/dataLayer';
import '@/vee-validate';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

Vue.use(dataLayer);
Vue.use(errorReporting, {
    disabled: process.env.VUE_APP_STACKDRIVER_ENABLED !== 'true',
    key: process.env.VUE_APP_STACKDRIVER_KEY,
    projectId: process.env.VUE_APP_STACKDRIVER_PROJECT_ID,
    reportUncaughtExceptions: true,
    reportUnhandledPromiseRejections: true,
    service: process.env.VUE_APP_NAME,
    version: process.env.PACKAGE_VERSION,
});

const locale = navigator.language.split('-')[0];

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.locale(locale);

Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false;

if (!process.env.VUE_APP_STANDALONE_SINGLE_SPA) {
    Vue.use(auth);

    new Vue({
        i18n,
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
}

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render(h) {
            return h(App, {
                props: {
                    authClient: this.authClient,
                },
            });
        },
        i18n,
        router,
        store,
        vuetify,
    },
});

export const bootstrap = (props) =>
    vueLifecycles.bootstrap().then(() => {
        Vue.prototype.$auth = props.authClient;
    });
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
