import {
    APPLY_TEMPORARY_FILTERS_TO_CURRENT,
    RESET_TEMPORARY_FILTERS,
    SET_AGGREGATION,
    SET_DATE_FILTER,
    SET_SELECTED_CONCEPT_UUIDS,
    SET_SELECTED_KITCHEN_LOCATION_UUIDS,
    SET_SELECTED_PLATFORM_UUIDS,
    SET_TEMPORARY_CONCEPT_UUIDS,
    SET_TEMPORARY_DATE_FILTER,
    SET_TEMPORARY_KITCHEN_LOCATION_UUIDS,
    SET_TEMPORARY_KITCHEN_TYPES,
    SET_TEMPORARY_PLATFORMS_UUIDS,
    TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS,
} from '@/store/modules/ui/filters/reporting/mutationTypes';
import { cloneDeep, merge } from 'lodash';

export default {
    [APPLY_TEMPORARY_FILTERS_TO_CURRENT]: (state) => {
        state.current = cloneDeep(state.temporary);
    },

    [RESET_TEMPORARY_FILTERS]: (state) => {
        state.temporary = cloneDeep(state.current);
    },

    [SET_AGGREGATION]: (state, aggregation) => {
        state.current.dateFilter.aggregation = aggregation;
    },

    [SET_DATE_FILTER]: (state, payload) => {
        state.current.dateFilter = merge({}, state.current.dateFilter, payload);
    },

    [SET_SELECTED_CONCEPT_UUIDS]: (state, selectedConceptUuids) => {
        state.current.selectedConceptUuids = selectedConceptUuids;
    },

    [SET_SELECTED_KITCHEN_LOCATION_UUIDS]: (state, selectedKitchenLocationUuids) => {
        state.current.selectedKitchenLocationUuids = selectedKitchenLocationUuids;
    },

    [SET_SELECTED_PLATFORM_UUIDS]: (state, selectedPlatformUuids) => {
        state.current.selectedPlatformUuids = selectedPlatformUuids;
    },

    [SET_TEMPORARY_KITCHEN_LOCATION_UUIDS]: (state, selectedKitchenLocationUuids) => {
        state.temporary.selectedKitchenLocationUuids = selectedKitchenLocationUuids;
    },

    [TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS]: (state) => {
        state.temporary.showDeletedKitchens = !state.temporary.showDeletedKitchens;
    },

    [SET_TEMPORARY_KITCHEN_TYPES]: (state, kitchenTypes) => {
        state.temporary.kitchenTypes = kitchenTypes;
    },

    [SET_TEMPORARY_CONCEPT_UUIDS]: (state, selectedConceptUuids) => {
        state.temporary.selectedConceptUuids = selectedConceptUuids;
    },

    [SET_TEMPORARY_PLATFORMS_UUIDS]: (state, selectedPlatformUuids) => {
        state.temporary.selectedPlatformUuids = selectedPlatformUuids;
    },

    [SET_TEMPORARY_DATE_FILTER]: (state, payload) => {
        state.temporary.dateFilter = merge({}, state.temporary.dateFilter, payload);
    },
};
