import ordersApi from '@/apis/orders';
import { SET_ORDERS } from '@/store/modules/orders/mutationTypes';

const fetchOrders = async ({ commit, dispatch }, payload) => {
    dispatch('status/setLoadingStatus', { stateKey: 'orders', isLoading: true }, { root: true });
    try {
        const orders = await ordersApi.fetchOrders(
            payload.number,
            payload.day,
            payload.locationUuid,
            payload.conceptUuid,
            payload.platformUuid,
            payload.summary
        );
        commit(SET_ORDERS, orders);
    } catch (error) {
        dispatch('status/setError', { stateKey: 'orders', error }, { root: true });
        throw error;
    } finally {
        dispatch('status/setLoadingStatus', { stateKey: 'orders', isLoading: false }, { root: true });
    }
};

export default {
    fetchOrders,
};
