import {
    SET_SELECTED_KITCHEN_LOCATION_UUIDS,
    SET_SELECTED_KITCHEN_TYPES,
    SET_SHOW_DELETED_KITCHENS,
} from '@/store/modules/ui/filters/invoices/mutationTypes';

export default {
    [SET_SELECTED_KITCHEN_LOCATION_UUIDS]: (state, selectedKitchenLocationUuids) => {
        state.selectedKitchenLocationUuids = selectedKitchenLocationUuids;
    },

    [SET_SHOW_DELETED_KITCHENS]: (state, showDeletedKitchens) => {
        state.showDeletedKitchens = showDeletedKitchens;
    },

    [SET_SELECTED_KITCHEN_TYPES]: (state, kitchenTypes) => {
        state.kitchenTypes = kitchenTypes;
    },
};
