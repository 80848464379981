import actions from '@/store/modules/oneBoard/quality/actions';
import mutations from '@/store/modules/oneBoard/quality/mutations';
import getters from '@/store/modules/oneBoard/quality/getters';

export default {
    namespaced: true,
    state: {
        splitChartByConcept: {},
        summary: {},
        timeseriesByConcept: {},
        timeseriesByLocation: {},
    },
    mutations,
    actions,
    getters,
};
