import {
    SET_SELECTED_KITCHEN_LOCATION_UUIDS,
    SET_SELECTED_KITCHEN_TYPES,
    SET_SHOW_DELETED_KITCHENS,
} from '@/store/modules/ui/filters/invoices/mutationTypes';

const setSelectedKitchenLocationUuids = ({ commit }, selectedKitchenLocationUuids) => {
    commit(SET_SELECTED_KITCHEN_LOCATION_UUIDS, selectedKitchenLocationUuids);
};

const setSelectedKitchenTypes = ({ commit }, kitchenTypes) => {
    commit(SET_SELECTED_KITCHEN_TYPES, kitchenTypes);
};

const setShowDeletedKitchens = ({ commit }, showDeletedKitchens) => {
    commit(SET_SHOW_DELETED_KITCHENS, showDeletedKitchens);
};

export default {
    setSelectedKitchenLocationUuids,
    setSelectedKitchenTypes,
    setShowDeletedKitchens,
};
