import {
    APPLY_TEMPORARY_FILTERS_TO_CURRENT,
    RESET_TEMPORARY_FILTERS,
    SET_AGGREGATION,
    SET_DATE_FILTER,
    SET_SELECTED_CONCEPT_UUIDS,
    SET_SELECTED_KITCHEN_LOCATION_UUIDS,
    SET_SELECTED_PLATFORM_UUIDS,
    SET_TEMPORARY_CONCEPT_UUIDS,
    SET_TEMPORARY_DATE_FILTER,
    SET_TEMPORARY_KITCHEN_LOCATION_UUIDS,
    SET_TEMPORARY_KITCHEN_TYPES,
    SET_TEMPORARY_PLATFORMS_UUIDS,
    TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS,
} from '@/store/modules/ui/filters/reporting/mutationTypes';

const applyTemporaryFiltersToCurrent = ({ commit }) => {
    commit(APPLY_TEMPORARY_FILTERS_TO_CURRENT);
};

const resetTemporaryFilters = ({ commit }) => {
    commit(RESET_TEMPORARY_FILTERS);
};

const setAggregation = ({ commit }, aggregation) => {
    commit(SET_AGGREGATION, aggregation);
};

const setDateFilter = ({ commit }, payload) => {
    commit(SET_DATE_FILTER, payload);
};

const setSelectedKitchenLocationUuids = ({ commit }, selectedKitchenLocationUuids) => {
    commit(SET_SELECTED_KITCHEN_LOCATION_UUIDS, selectedKitchenLocationUuids);
};

const setSelectedConceptUuids = ({ commit }, selectedConceptUuids) => {
    commit(SET_SELECTED_CONCEPT_UUIDS, selectedConceptUuids);
};

const setSelectedPlatformUuids = ({ commit }, selectedPlatformUuids) => {
    commit(SET_SELECTED_PLATFORM_UUIDS, selectedPlatformUuids);
};

const setTemporaryKitchenTypes = ({ commit }, kitchenTypes) => {
    commit(SET_TEMPORARY_KITCHEN_TYPES, kitchenTypes);
};

const toggleTemporaryShowDeletedKitchens = ({ commit }) => {
    commit(TOGGLE_TEMPORARY_SHOW_DELETED_KITCHENS);
};

const setTemporaryKitchenLocationUuids = ({ commit }, selectedKitchenLocationUuids) => {
    commit(SET_TEMPORARY_KITCHEN_LOCATION_UUIDS, selectedKitchenLocationUuids);
};

const setTemporaryConceptUuids = ({ commit }, selectedConceptUuids) => {
    commit(SET_TEMPORARY_CONCEPT_UUIDS, selectedConceptUuids);
};

const setTemporaryPlatformUuids = ({ commit }, selectedConceptUuids) => {
    commit(SET_TEMPORARY_PLATFORMS_UUIDS, selectedConceptUuids);
};

const setTemporaryDateFilter = ({ commit }, payload) => {
    commit(SET_TEMPORARY_DATE_FILTER, payload);
};

export default {
    applyTemporaryFiltersToCurrent,
    resetTemporaryFilters,
    setAggregation,
    setDateFilter,
    setSelectedConceptUuids,
    setSelectedKitchenLocationUuids,
    setSelectedPlatformUuids,
    setTemporaryConceptUuids,
    setTemporaryDateFilter,
    setTemporaryKitchenLocationUuids,
    setTemporaryKitchenTypes,
    setTemporaryPlatformUuids,
    toggleTemporaryShowDeletedKitchens,
};
