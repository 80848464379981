import actions from '@/store/modules/oneBoard/finance/actions';
import mutations from '@/store/modules/oneBoard/finance/mutations';
import getters from '@/store/modules/oneBoard/finance/getters';

export default {
    namespaced: true,
    state: {
        comparisonSummary: {},
        summary: {},
        timeChart: {},
        timeseriesByLocation: {},
    },
    mutations,
    actions,
    getters,
};
