<template>
    <div class="page-layout">
        <v-app-bar v-if="$vuetify.breakpoint.smAndDown" elevation="0" fixed clipped-left app>
            <v-app-bar-nav-icon @click="toggleDrawer">
                <v-icon>menu</v-icon>
            </v-app-bar-nav-icon>
        </v-app-bar>
        <div class="page-layout-wrapper">
            <SideBarNavigation :is-drawer-shown="isDrawerShown" @update:isDrawerShown="setIsDrawerShown" />
            <v-main>
                <slot />
            </v-main>
        </div>
    </div>
</template>

<script>
import SideBarNavigation from '@/components/SideBarNavigation/SideBarNavigation';

export default {
    components: { SideBarNavigation },
    data() {
        return {
            isDrawerShown: false,
        };
    },
    methods: {
        setIsDrawerShown(newValue) {
            this.isDrawerShown = newValue;
        },
        toggleDrawer() {
            this.isDrawerShown = !this.isDrawerShown;
        },
    },
};
</script>

<style scoped lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: white;
}

.page-layout {
    height: 100%;
}

.page-layout-wrapper {
    height: 100%;
}

.v-main {
    height: 100%;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
    .v-main {
        margin-left: 280px;
    }
}

.navigation-taster {
    border-radius: 0 1.25rem 1.25rem 0;
    border: none;
    padding: 20px 0 0 0;
}

.selected-tab-divider {
    letter-spacing: 0.3px;
}

.taster-app-mobile {
    background-color: #ffffff !important;
    box-shadow: 0 2px 7px rgba(175, 175, 175, 0.25) !important;
}
</style>
