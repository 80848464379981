import { SHOW_NOTIFICATION } from '@/store/modules/notifier/mutationTypes';

export default {
    [SHOW_NOTIFICATION]: (state, { message, subText, type, position }) => {
        state.message = message;
        state.subText = subText;
        state.type = type;
        state.position = position;
    },
};
