import Vue from 'vue';

export const roles = {
    DeliveriesPerformance: 'Deliveries Performance',
    ConceptAdministrator: 'Concept Administrator', // Global menu template management for concept and country
    InvoicesBeta: 'Invoices Beta', // Temporary - Access to Invoices beta
    ItemLibraryUser: 'Item Library User', // Access to menu manager item library
    LocalizedMenuManagerUser: 'Localized Menu Manager User', // Enable/Disable menu items, published revised menu for authorized locations and concepts
    RestaurantLiveViewer: 'Restaurant Live Viewer', // View live kitchen state (OH, States, Special Hours, OOS); view menu (restaurant tab)
    RestaurantPerformance: 'Restaurant Performance', // Kitchen operations overview (Rating, Preptime, Counts, Reviews, Hygiene, Comments Tagging)
    RestaurantRevenue: 'Restaurant Revenue', // Kitchen accounting (Order count, revenue, food costs, invoices)
    RestaurantStateController: 'Restaurant State Controller', // Edit kitchen state on platforms (OH, states, Special Hours, OOS)
    SupplyHistoryViewer: 'Supply History Viewer', // Can see order history, incoming deliveries and current orders
    SupplyOrderEditor: 'Supply Order Editor', // Create and Send a system order, edit sent order
    SupplyReceptionController: 'Supply Reception Controller', // Can see incoming deliveries and declare issues"
    KitchensViewer: 'Kitchens Viewer',
    // live:read
    LiveViewer: 'Live Viewer',
    // mep:read
    MepViewer: 'MEP Viewer',
    // finance:read
    FinanceViewer: 'Finance Viewer',
    // orders:read
    OrdersViewer: 'Orders Viewer',
    // performances:read
    PerformanceViewer: 'Performance Viewer',
    // quality:read
    QualityViewer: 'Quality Viewer',
    // quality:read
    HygieneViewer: 'Hygiene Viewer',
    // inventories:read
    SupplyController: 'Supply Controller',
    // awaiting-validation-orders:read
    SupplyOrderValidator: 'Supply Order Validator',
    // inventories:read & awaiting-validation-orders:read
    SupplyAdmin: 'Supply Admin',
    // invoice:read
    InvoicesViewer: 'Published invoice Viewer',
    // openings : read
    OpeningsViewer: 'Openings Viewer',
    // opening hours : read
    OpeningHoursViewer: 'Opening Hours Viewer',
    // specialHours : read
    SpecialHoursViewer: 'Special Hours Viewer',
    MenuManagerMenuCreator: 'Menu Manager Menu Creator',
    MenuManagerMenuPublisher: 'Menu Manager Menu Publisher',
    MenuManagerRestaurantsViewer: 'Menu Manager Restaurants Viewer',
    MenuManagerTemplatesViewer: 'Menu Manager Templates Viewer',
    MenuManagerViewer: 'Menu Manager Viewer',
    // leaderboard : read
    LeaderboardViewer: 'Leaderboard Viewer',
    // leaderboard : read
    RefundsViewer: 'Refunds Viewer',
};

export const hasRole = (role) => Vue.prototype.$auth.userRoles.includes(role);
