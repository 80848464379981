import { performanceApi } from '@/apis/oneBoard/clients';

export default {
    fetchPerformanceSummary({ aggregation, conceptUuids, endDate, locationUuids, platformUuids, startDate }) {
        return performanceApi.fetchPerformanceSummary({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            start_date: startDate,
        });
    },
    fetchPerformanceSplitChart({
        aggregation,
        conceptUuids,
        endDate,
        locationUuids,
        platformUuids,
        splitBy,
        startDate,
    }) {
        return performanceApi.fetchPerformanceSplitChart({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            split_by: splitBy,
            start_date: startDate,
        });
    },
    fetchPerformanceTimeChart({ aggregation, conceptUuids, endDate, locationUuids, platformUuids, startDate }) {
        return performanceApi.fetchPerformanceTimeChart({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            start_date: startDate,
        });
    },
    fetchPerformanceTimeseries({
        aggregation,
        conceptUuids,
        endDate,
        locationUuids,
        platformUuids,
        splitBy,
        startDate,
    }) {
        return performanceApi.fetchPerformanceTimeseries({
            aggregation,
            concept_uuids: conceptUuids,
            end_date: endDate,
            location_uuids: locationUuids,
            platform_uuids: platformUuids,
            split_by: splitBy,
            start_date: startDate,
        });
    },
};
