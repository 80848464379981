import {
    SET_FINANCE_COMPARISON_SUMMARY,
    SET_FINANCE_SUMMARY,
    SET_FINANCE_TIME_CHART,
    SET_FINANCE_TIME_SERIES_BY_LOCATION,
} from '@/store/modules/oneBoard/finance/mutationTypes';

export default {
    [SET_FINANCE_COMPARISON_SUMMARY]: (state, comparisonSummary) => {
        state.comparisonSummary = comparisonSummary;
    },
    [SET_FINANCE_SUMMARY]: (state, summary) => {
        state.summary = summary;
    },
    [SET_FINANCE_TIME_CHART]: (state, timeChart) => {
        state.timeChart = timeChart;
    },
    [SET_FINANCE_TIME_SERIES_BY_LOCATION]: (state, timeseriesByLocation) => {
        state.timeseriesByLocation = timeseriesByLocation;
    },
};
