import actions from '@/store/modules/ui/filters/invoices/actions';
import mutations from '@/store/modules/ui/filters/invoices/mutations';
import getters from '@/store/modules/ui/filters/invoices/getters';

export default {
    namespaced: true,
    state: {
        kitchenTypes: ['partners'],
        selectedKitchenLocationUuids: [],
        showDeletedKitchens: false,
    },
    actions,
    mutations,
    getters,
};
